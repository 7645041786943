import { ref } from 'vue'
import axios from "axios";
import { useRouter } from 'vue-router'

const loggedIn = ref(!!localStorage.getItem('pagewatch-token'))
const userLoaded = ref(false)
axios.defaults.baseURL = import.meta.env.VITE_API_ROOT;
let token = localStorage.getItem('pagewatch-token');
if (!document.location.pathname.startsWith("/r/") && document.location.pathname.split('/')[1].length === 36){
    token = document.location.pathname.split('/')[1]
}
axios.defaults.headers.common['Authorization'] = `Token ${token}`;

export function useAuth() {
    const router = useRouter()
    const login = async (email, password) => {
        try {
            await logout(false);
            let r = await axios.post('user/login/', {email: email, password: password})
            localStorage.setItem('pagewatch-token', r.data.token)
            axios.defaults.headers.common['Authorization'] = `Token ${localStorage.getItem('pagewatch-token')}`;
            loggedIn.value = true
            return {
                success: true,
            }
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    message: error.response.data.detail
                }
            } else {
                return {
                    success: false,
                    message: "We were unable to reach the server, please try again later."
                }
            }
        }
    }

    const register = async (email, password) => {
        try {
            await logout(false);
            let r = await axios.post('user/register/', {email: email, password: password})
            localStorage.setItem('pagewatch-token', r.data.token)
            axios.defaults.headers.common['Authorization'] = `Token ${localStorage.getItem('pagewatch-token')}`;
            loggedIn.value = true
            return {
                success: true,
            }
        } catch (error) {
            if (error.response) {
                return {
                    success: false,
                    message: error.response.data.detail
                }
            } else {
                return {
                    success: false,
                    message: "We were unable to reach the server, please try again later."
                }
            }
        }
    }

    async function logout(redirect = true) {
        localStorage.removeItem('pagewatch-token')
        axios.defaults.headers.common['Authorization'] = null
        loggedIn.value = false
        userLoaded.value = false
        if (redirect) {
            await router.replace({name: 'auth-login'})
            // refresh the page to clear the cache
            location.reload()
        }
    }

    return {
        loggedIn, login, logout, userLoaded, register
    }
}

