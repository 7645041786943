import { watch } from "vue";
import { useRoute } from "vue-router";

let tooltipList = [];
export default {
    setup(){
        const route = useRoute()
        watch(() => route.query, (query) => {
        })
    },
    mounted: function (el, binding) {
        el.title = binding.value;
        tooltipList.push(new window.bootstrap.Tooltip(el, {
            container: "#page-container",
            trigger: 'hover',
        }));

    },

    onBeforeUnmount: (el, binding) => {
        tooltipList.forEach((tooltip) => tooltip.dispose());
    },
};
