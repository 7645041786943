import { createApp, provide, h } from "vue"
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";

import router from "./router/routes";

// Template components
import BaseBlock from "@/components/BaseBlock.vue";
import BasePageHeading from "@/components/BasePageHeading.vue";

// Template directives
import tooltip from "@/directives/tooltip";
import linkify from "@/directives/linkify";

// Bootstrap framework
import * as bootstrap from "bootstrap";

import { DateTime } from "luxon";

window.bootstrap = bootstrap;
import { DefaultApolloClient } from "@vue/apollo-composable"
import { apolloClient } from "./apollo-client"
import VueRoughNotation from 'vue-rough-notation';
import { createHead } from '@unhead/vue';

const app = createApp({
    setup() {
        provide(DefaultApolloClient, apolloClient)
    },
    render: () => h(App),
})

// Set up the global error handler before sentry is initialized
app.config.errorHandler = (err, instance, info) => {
  // Handle the error globally
  console.error("Global error:", err);
  console.log("Vue instance:", instance);
  console.log("Error info:", info);
};

if (import.meta.env.SENTRY_AUTH_TOKEN) {
    Sentry.init({
        app,
        dsn: "https://5b5b555093d8275e55b86589f41c5b43@o298252.ingest.us.sentry.io/4507336385429504",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/pagewatch\.ai/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
const head = createHead()
app.use(head)
app.use(VueRoughNotation);
app.config.globalProperties.$filters = {
    truncate(value, length = 36) {
        if (!value) {
            return ''
        }
        if (value.length >= length) {
            return value.substring(0, length) + '...'
        }
        return value

    },
    dateFilter(value) {
        const d = DateTime.fromISO(value);
        return d.toFormat("d MMM h:mm a")
    },
    dateRelative(value) {
        const d = DateTime.fromISO(value);
        return d.toRelative()
    }
}

// Use Pinia and Vue Router
app.use(createPinia());
// Register global components
app.component("BaseBlock", BaseBlock);
app.component("BasePageHeading", BasePageHeading);

// Register global directives
app.directive("tooltip", tooltip);
app.directive("linkify", linkify);

import VueApexCharts from "vue3-apexcharts";

app.use(VueApexCharts);

import { useAuth } from "@/composables/auth";



app.use(router);
router.beforeEach(async (to, from) => {
    const {loggedIn} = useAuth()
     document.title = to.meta.title || "PageWatch";
})
// await router.isReady()
app.mount("#app");
