import { defineStore, storeToRefs } from 'pinia'
import { ref, watch } from "vue";
import { gql } from "graphql-tag";
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import { useWebsiteStore } from "@/stores/website";


export const useActiveDeviceRenderStore = defineStore('activeDeviceRender', () => {
    const deviceRenders = ref([]);
    const devicePreview = ref(null);
    const deviceRender = ref(null);
    const historyData = ref(null);
    const page = ref(null);
    const lighthouseData = ref(null);
    const loaded = ref(false);
    const loadingState = ref('');
    //  in_progress, completed
    const scanState = ref('completed');
    const initialProgress = ref(5)
    const route = useRoute()
    const websiteStore = useWebsiteStore()
    const {lastUpdated, website} = storeToRefs(websiteStore)

    let canonicalPage = {}

    let progress = 5;
    const HISTORY_RENDERS = gql`
        query DeviceRender($renderIds: [bigint!]) {
          websites_devicerender(where: {id: {_in: $renderIds}}, order_by: {id: desc}){
            id
            status
            error_reason
            screenshots
            diff_marks
            pageversion {
                 id
                 crawl_date
            }
          }
      }`;
    const historyRenderVariables = ref({
        renderIds: [],
        lastId: 1,
    })
    const historyRenders = useQuery(HISTORY_RENDERS, historyRenderVariables, () => ({
            enabled: historyRenderVariables.value.renderIds.length > 0,
            fetchPolicy: 'network-only',
        })
    )
    watch(
        historyRenders.result,
        value => {
            if (value) {
                let diff_marks = []
                for (let diff_mark of value.websites_devicerender[0].diff_marks) {
                    let box = {
                        left: diff_mark.box.x,
                        top: diff_mark.box.y,
                        width: diff_mark.box.width,
                        height: diff_mark.box.height,
                        text: diff_mark.box.text,
                        node: diff_mark.box.node,
                    }
                    let parentBox = {}
                    if (diff_mark.parent && diff_mark.parent.x) {
                        parentBox = {
                            left: diff_mark.parent.x,
                            top: diff_mark.parent.y,
                            width: diff_mark.parent.width,
                            height: diff_mark.parent.height,
                            text: diff_mark.parent.text,
                            node: diff_mark.parent.node,
                        }
                    }
                    diff_marks.push({type: diff_mark.type, box: box, parent: parentBox})
                }
                diff_marks.sort((a, b) => {
                    if (a.box.top > b.box.top) {
                        return 1
                    }
                    if (a.box.top < b.box.top) {
                        return -1
                    }
                    return 0
                })
                if (value.websites_devicerender.length === 1) {
                    historyData.value = {
                        state: 'active',
                        dateOld: '',
                        dateNew: value.websites_devicerender[0].pageversion.crawl_date,
                        diff_marks: diff_marks,
                        screenshotsNew: value.websites_devicerender[0].screenshots,
                        screenshotsOld: {
                            full: {
                                url: '',
                                success: value.websites_devicerender[0].screenshots['full'].success,
                                height: value.websites_devicerender[0].screenshots['full'].height,
                                width: value.websites_devicerender[0].screenshots['full'].width,
                            }
                        },
                    }
                } else {
                    historyData.value = {
                        state: 'active',
                        dateOld: value.websites_devicerender[1].pageversion.crawl_date,
                        dateNew: value.websites_devicerender[0].pageversion.crawl_date,
                        diff_marks: diff_marks,
                        screenshotsNew: value.websites_devicerender[0].screenshots,
                        screenshotsOld: value.websites_devicerender[1].screenshots,
                    }
                }
            }
        }
    );

    const SELECT_CANONICAL_PAGE = gql`
        query CanonicalPage($websiteId: bigint, $externalId: Int) {
         websites_canonicalpage(where: {website_id:{_eq: $websiteId}, external_id: {_eq: $externalId}}){
            id
            external_id
            status
            path
            title    
            history
            website{
              id
              url
            }
            latest_page_version{
              id
              page_score
              console_log_issues
              broken_resource_issues
              lighthouse
              linkcheck_state
              performance_check_state
              http_status_code
              processing_status
              content_issue_missing_favicon
              seo_issue_missing_title
              seo_issue_missing_description
              crawl_date
              device_renders{
                id
                status
                error_reason
                screenshots
                crawl_errors
                layout_errors
                broken_link_marks
                content_issue_marks
                broken_image_marks
                missing_alt_tag_image_marks
                spelling_marks
                device{
                  id
                  name
                }
              }
            }        
      } 
  }`
    const canonicalPageVariables = ref({
        websiteId: 0,
        externalId: 0,
        // lastId: 1,
    })

    function setHistoryRenders() {
        if (!canonicalPage.history) {
            return
        }
        let historyIndex = parseInt(route.query.history, 10) || 0;
        if (historyIndex > 0 && canonicalPage.history.length > historyIndex) {
            let newEntry = canonicalPage.history[historyIndex]
            let oldEntry = canonicalPage.history[historyIndex - 1]
            if (newEntry && newEntry.device_renders && oldEntry && oldEntry.device_renders) {
                historyRenderVariables.value.renderIds = [
                    oldEntry.device_renders.find((device_render) => {
                        return device_render.device === route.params.deviceId
                    }).id, newEntry.device_renders.find((device_render) => {
                        return device_render.device === route.params.deviceId
                    }).id]
            }
            historyRenderVariables.value.lastId += 1;
        } else if (historyIndex > 0 && canonicalPage.history.length === historyIndex) {
            let newEntry = canonicalPage.history[historyIndex - 1]
            if (newEntry && newEntry.device_renders) {
                historyRenderVariables.value.renderIds = [
                    newEntry.device_renders.find((device_render) => {
                        return device_render.device === route.params.deviceId
                    }).id, newEntry.device_renders.find((device_render) => {
                        return device_render.device === route.params.deviceId
                    }).id]
            }
            historyRenderVariables.value.lastId += 1;
        } else {
            historyRenderVariables.value.renderIds = []
            historyData.value = null
        }
    }

    function setDeviceRender() {
        if (!(canonicalPage && canonicalPage.latest_page_version)) {
            return
        }
        let deviceRenderEntry = canonicalPage.latest_page_version.device_renders.find((device_render) => {
            return device_render.device.id === route.params.deviceId
        })
        if (!deviceRenderEntry) {
            return
        }
        devicePreview.value = {
            id: deviceRenderEntry.id,
            status: deviceRenderEntry.status,
            errorReason: deviceRenderEntry.error_reason,
            deviceId: deviceRenderEntry.device.id,
            deviceName: deviceRenderEntry.device.name,
            screenshot: deviceRenderEntry.screenshots['main'] || {},
            screenshotFull: deviceRenderEntry.screenshots['full'] || {}
        }

        deviceRender.value = deviceRenders.value.find( (d) => d.deviceId === route.params.deviceId)
        setHistoryRenders();
    }

    const {
        result,
        refetch,
    } = useQuery(SELECT_CANONICAL_PAGE, canonicalPageVariables, () => ({
            enabled: !!canonicalPageVariables.value.websiteId && !!canonicalPageVariables.value.externalId,
        // fetchPolicy: 'cache-and-network',
        })
    )
    watch(
        result,
        value => {
            if (value && value.websites_canonicalpage) {
                canonicalPage = value.websites_canonicalpage[0]
                deviceRenders.value = []

                let customDictionaryWords = website.value.customDictionary.words || [];

                for (let device_render of canonicalPage.latest_page_version.device_renders) {
                    deviceRenders.value.push({
                        id: device_render.id,
                        status: device_render.status,
                        deviceId: device_render.device.id,
                        name: device_render.device.name,
                        spellingMarks: (device_render.spelling_marks || []).filter( (s) => s.reason !== 'miscapitalized' && !customDictionaryWords.includes(s.word)),
                        inconsistentCapitalizationMarks: (device_render.spelling_marks || []).filter( (s) => s.reason === 'miscapitalized' && ! customDictionaryWords.includes(s.word)),
                        crawlErrors: device_render.crawl_errors || [],
                        layoutErrors: device_render.layout_errors || [],
                        brokenLinkMarks: (device_render.broken_link_marks || []).filter( (l) => l.reason !== 'internal_redirect'),
                        internalRedirectMarks: (device_render.broken_link_marks || []).filter( (l) => l.reason === 'internal_redirect'),
                        brokenImageMarks: device_render.broken_image_marks || [],
                        contentIssueMarks: device_render.content_issue_marks || [],
                        missingAltTagImageMarks: device_render.missing_alt_tag_image_marks || [],
                        screenshot: device_render.screenshots['main'] || {},
                        screenshotFull: device_render.screenshots['full'] || {}
                    })
                }

                page.value = {
                    externalId: canonicalPage.external_id,
                    title: canonicalPage.title,
                    url: canonicalPage.website.url + canonicalPage.path,
                    status: canonicalPage.status,
                    score: canonicalPage.latest_page_version.page_score,
                    history: canonicalPage.history,
                    crawlDate: canonicalPage.latest_page_version.crawl_date,
                    httpStatusCode: canonicalPage.latest_page_version.http_status_code,
                    httpStatusOkOrPending: (canonicalPage.latest_page_version.http_status_code >= 200 && canonicalPage.latest_page_version.http_status_code < 300) || canonicalPage.latest_page_version.http_status_code === -1,
                    consoleLogIssues: canonicalPage.latest_page_version.console_log_issues,
                    brokenResourceIssues: canonicalPage.latest_page_version.broken_resource_issues,
                    contentIssueMissingFavicon: canonicalPage.latest_page_version.content_issue_missing_favicon,
                    seoIssueMissingTitle: canonicalPage.latest_page_version.seo_issue_missing_title,
                    seoIssueMissingDescription: canonicalPage.latest_page_version.seo_issue_missing_description,
                    lighthouse: canonicalPage.latest_page_version.lighthouse,
                    progress: {
                        percentage: initialProgress.value,
                        renders: {
                            total: canonicalPage.latest_page_version.device_renders.length,
                            completed: canonicalPage.latest_page_version.device_renders.filter((device_render) => {
                                return (device_render.status === 'error' || device_render.screenshots['full'])
                            }).length,
                            partial: canonicalPage.latest_page_version.device_renders.filter((device_render) => {
                                return (device_render.status === 'error' || device_render.screenshots['main'])
                            }).length
                        },
                        performance: ['completed', 'error'].includes(canonicalPage.latest_page_version.performance_check_state),
                        linkCheck: canonicalPage.latest_page_version.linkcheck_state === 'completed',
                    }
                }

                setDeviceRender()
                loadingState.value = 'pending'

                lighthouseData.value = canonicalPage.latest_page_version.lighthouse
                loaded.value = true
            }
        }
    );

    watch(() => route.params, async (query) => {
        if (canonicalPageVariables.value.externalId !== parseInt(query.pageId,10)) {
            if (parseInt(query.pageId,10)) {
                loaded.value = false
                canonicalPageVariables.value.externalId = parseInt(query.pageId, 10)
                await refetch()
            }
        }
        // canonicalPageVariables.value.externalId = parseInt(query.pageId,10)
        setDeviceRender()
    },
        {immediate: true}
    );
    watch(() => route.query, (query) => {
        setHistoryRenders()
    });
    watch(lastUpdated, async(value) => {
        if (!value) {
            return
        }
        canonicalPageVariables.value.websiteId = website.value.id;
        await refetch();
        // canonicalPageVariables.value.lastId = canonicalPageVariables.value.lastId + 1
    })

    async function loadLighthouseData(url, force=false) {
        if (lighthouseData.value && lighthouseData.value.url === url && !force) {
            return
        }
        lighthouseData.value = null
        try {
            let response = await fetch(url)
            if (!response.ok) {
                lighthouseData.value = {
                    success: false,
                    url: url,
                    error: 'Failed to load lighthouse data',
                }
            }
            lighthouseData.value = {
                success: true,
                url: url,
                report: await response.json()
            }
        } catch (e) {
            lighthouseData.value = {
                success: false,
                url: url,
                error: e,
            }
            console.error(e)
        }
    }

    return {
        loaded,
        scanState,
        loadingState,
        devicePreview,
        deviceRender,
        historyData,
        deviceRenders,
        lighthouseData,
        loadLighthouseData,
        page,
        refetch
    }
})