import { watch } from "vue";
import { useRoute } from "vue-router";
import linkifyHtml from "linkify-html";
let tooltipList = [];
export default {
    setup(){
        const route = useRoute()
    },
    mounted: function (el, binding) {

        el.innerHTML = linkifyHtml(el.innerHTML, {
            defaultProtocol: 'https',
            className: 'external-link-plain',
            target: {
                url: '_blank'
            }
        });
    },

    onBeforeUnmount: (el, binding) => {
        tooltipList.forEach((tooltip) => tooltip.dispose());
    },
};
