<script setup>
import axios from "axios";
import { useWebsiteStore } from "@/stores/website";
import { storeToRefs } from "pinia";

const websiteStore = useWebsiteStore();
import VueformSlider from "@vueform/slider";
import { ref } from "vue";

const props = defineProps({
  loading: Boolean,
  url: String,
  meta: Object,
});
const {lastUpdated, website} = storeToRefs(websiteStore);

async function runScan() {
  const myModal = bootstrap.Modal.getInstance(document.getElementById('modal-share-report'))


  let r = await axios.post(`websites/${website.value.shortuuid}/recheck/`, {
    scope: 'site',
  })
  if (r.data.success) {
    myModal.hide()
  }

}

const maxPages = ref(100)

function format(v) {
  return `${v} pages`
}

</script>

<template>
  <div
      class="modal modal-lg"
      id="modal-share-report"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-share-report"
      aria-hidden="true"
  >
    <div class="modal-dialog  modal-dialog-centered" role="document">
      <div class="modal-content">
        <BaseBlock title="Share a public link to this page" transparent class="mb-0">
          <template #options>
            <button
                type="button"
                class="btn-block-option"
                data-bs-dismiss="modal"
                aria-label="Close"
            >
              <i class="fa fa-fw fa-times"></i>
            </button>
          </template>

          <template #content>
            <div class="block-content fs-sm">
              <p>Anyone with the following link can view this report. The link will grant read only access to all pages
                in this project. </p>
              <img :src="meta.image" v-if="meta && meta.image" height="1" width="1" style="visibility: hidden">
              <div class="mb-3" v-if="!loading">
                <input :value="url" type="text" class="form-control form-control-sm" id="exampleInputPassword1">
              </div>
              <div v-else class="mb-3">
                    <span class="spinner-border text-primary ms-2 me-2 text-muted"
                          style="font-size:12px;height:18px;width:18px">      </span>
              </div>
            </div>
          </template>
        </BaseBlock>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// Vue Form Slider + Custom overrides
@import "@vueform/slider/themes/default.css";
@import "@/assets/scss/vendor/vueform-slider";
</style>