<script setup>
import { useTemplateStore } from "@/stores/template";

import { useWebsiteStore } from "@/stores/website";
import { useUserStore } from "@/stores/user";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";

import("@/views/explore/AllPagesView.vue")

import("@/views/dashboard/DashboardView.vue");
import("@/views/account/DashboardView.vue");
import("@/views/account/Credits.vue");

import("@/views/sites/NewSiteView.vue");
import("@/views/spelling/SpellingView.vue");
import("@/views/spelling/CapitalizationIssuesView.vue");
import("@/views/layout_issues/LayoutIssues.vue");
import("@/views/broken_images/BrokenImages.vue");
import("@/views/broken_links/BrokenLinks.vue");
import("@/views/settings/Settings.vue");
import("@/views/competitors/ManageCompetitors.vue");
import("@/views/competitors/Analysis.vue");
import("@/views/broken_resources/BrokenResources.vue");
import("@/views/performance/SlowPages.vue");
import("@/views/content/Copyright.vue");
import("@/views/insights/Insights.vue");
import("@/views/visual/Visual.vue");
import("@/views/missing_titles/MissingTitles.vue");
import("@/views/missing_favicon/MissingFavicon.vue");
import("@/views/missing_descriptions/MissingDescriptions.vue");
import("@/views/seo/InternalRedirects.vue");
import("@/views/console_logs/ConsoleErrors.vue");
import("@/views/console_logs/ConsoleWarnings.vue");
import("@/views/audit/OpenIssues.vue");

useActiveDeviceRenderStore()
const websiteStore = useWebsiteStore();
const userStore = useUserStore();

import BaseLayout from "@/layouts/BaseLayout.vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const props = defineProps({
  prefix: String,
});
const store = useTemplateStore();
// Set default elements for this layout
store.setLayout({
  header: true,
  sidebar: true,
  sideOverlay: true,
  footer: true,
});

// Set various template options for this layout variationse
store.headerStyle({mode: "light"});
store.mainContent({mode: "narrow"});
</script>

<template>

  <div v-if="!((websiteStore.loaded || route.meta.sidebar === 'user') && userStore.loaded)">
    <div
        style="position: fixed; left:0;top:0;width:100%;height:100%;display:flex;justify-content: center;align-items:center">
      <div class="spinner-border text-primary">
        <span class="sr-only">Loading..</span>
      </div>
    </div>
    <div style="display:none">
      w{{websiteStore.loaded}}
      u{{userStore.loaded}}
    </div>
  </div>
  <BaseLayout v-else>

    <!-- Side Overlay Content -->
    <!-- Using the available v-slot, we can override the default Side Overlay content from layouts/partials/SideOvelay.vue -->
    <template #side-overlay-content>
      <div class="content-side">
        <p>Side Overlay content..</p>
      </div>
    </template>
    <!-- END Side Overlay Content -->

  </BaseLayout>
</template>
