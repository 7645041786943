<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import { useRoute, useRouter } from "vue-router";
import { renderReport } from "lighthouse-viewer";
import RadialProgress from "vue3-radial-progress";

const router = useRouter();
const route = useRoute()
const loaded = ref(false)
const activeDeviceRenderStore = useActiveDeviceRenderStore();
const activeDeviceRender = useActiveDeviceRenderStore();

const props = defineProps({
  devicePreview: Object,
});
let loadAttempt = 0;
function displayReport() {
  if (activeDeviceRenderStore.lighthouseData && activeDeviceRenderStore.lighthouseData.report) {
    let report = activeDeviceRenderStore.lighthouseData.report
    const performanceReport = {
      ...report,
      categories: {
        performance: report.categories.performance,
        // 'best-practices': report.categories["best-practices"],
        // seo: report.categories.seo,
        // accessibility: report.categories.accessibility,
      }
    };
    const app = document.getElementById('lighthouse-viewer-element');

    const reportHtml = renderReport(performanceReport, {});
    if (app) {
      app.appendChild(reportHtml);
      loaded.value = true

    }
  }
  else if (activeDeviceRenderStore.lighthouseData && activeDeviceRenderStore.lighthouseData.url){

    if (loadAttempt === 0) {
      loadAttempt += 1;
      activeDeviceRenderStore.loadLighthouseData(activeDeviceRenderStore.lighthouseData.url, true)
    }

  }
}

watch(() => activeDeviceRenderStore.lighthouseData, (value) => {
  loaded.value = false;
  displayReport()
}, {
  immediate: true
})

let performanceScore = computed(() =>{
  if (activeDeviceRender.page.lighthouse && activeDeviceRender.page.lighthouse.performance_score) {
    let p = activeDeviceRender.page.lighthouse.performance_score;
    // round to integer
    return Math.round(p * 100);
  }
  return ''

})

let progressColor = computed(() =>{
  if (performanceScore.value >= 90) {
    return 'green'
  }
  if (performanceScore.value >= 50) {
    return 'orange'
  }
  return 'red'

})

onMounted(async () => {
  loaded.value = false;
  displayReport()

})

</script>

<template>
  <div class="background-block p-3 mt-4">
    <RadialProgress style="float:right"
                    :diameter="68"
                    innerStrokeColor="gray"
                    :startColor="progressColor"
                    :stopColor="progressColor"
                    :strokeWidth="6"
                    :innerStrokeWidth="4"
                    :completed-steps="70"
                    :total-steps="100">
{{performanceScore}}
    </RadialProgress>
    <div class="heading-text">Page Performance</div>
    <small class="overflow-text-ellipsis text-muted pe-3">Performance is measured using <br>
      <a class="external-link" target="_blank" href="https://developer.chrome.com/docs/lighthouse">Google Lighthouse</a></small>

  </div>

  <div v-if="activeDeviceRenderStore.lighthouseData && !activeDeviceRenderStore.lighthouseData.success">
    <div class="container">
      <div class="row justify-content-end">
        <div class="col-12 p-4 mt-2">
          <div class="alert alert-danger" role="alert">
            <span class="fa fa-warning"></span> <b>Unable to gather performance data.</b>
            <p>
              If the issue persist please let us know.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div id="lighthouse-viewer-element" v-show="loaded">
    </div>
    <div v-if="!loaded" style="min-height: 400px;text-align: center">
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<style>
.lh-topbar {
  display: none !important
}

.lh-category-header {
  display: none !important
}

.lh-sticky-header {
  position: inherit !important;
}

.lh-table-column--url {
  min-width: inherit !important;
}
</style>