<script setup>

import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import HttpStatusCodeBadge from "@/components/HttpStatusCodeBadge.vue";
import { useWebsiteStore } from "@/stores/website";
import { storeToRefs } from "pinia";

const activeDeviceRender = useActiveDeviceRenderStore();
const router = useRouter()
const route = useRoute()
const props = defineProps({
  issues: Object,
  id: String,
  priority: {type: String, default: "low"},
  title: String,
});
const websiteStore = useWebsiteStore();
const {website} = storeToRefs(websiteStore);

function isOpen() {
  return route.query[props.id] !== undefined;
  // for (let issue of props.issues) {
  //   if (issue.id === route.query[props.id]) {
  //     return true
  //   }
  // }
  // return false;
}

async function ignoreIssue(issue) {
  await websiteStore.approveIssue(props.id, issue.id)

}
</script>

<template>
  <div class="accordion issue-block  position-relative" v-if="issues.length > 0">
    <a href="" v-if="0"><span v-tooltip="`Internal Redirect`"
                              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning"
                              style="z-index: 99;font-size:8pt;width:16px;height:16px;">
    <span class="fa fa-info" style="line-height: 0;"></span>
  </span></a>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button issue-header" type="button" data-bs-toggle="collapse"
                :class="{'collapsed': !isOpen()}" :data-bs-target="`#issue-${id}-${priority}`"
        >
          {{ issues.length }} {{ title }}{{ issues.length > 1 ? 's' : '' }}
        </button>
      </h2>
      <div  :id="`issue-${id}-${priority}`" :class="{'show': isOpen()}"
           class="accordion-collapse collapse issue-body" data-bs-parent="#accordion-issue-list">
        <div class="accordion-body">
          <ul class="list-group list-group-flush ">
            <div class="list-group-item list-group-item-action rounded  issue-item"
                 v-for="issue in issues || []" :key="issue.id"
                 :class="{'issue-selected': issue.id === route.query[id]}"
            >
              <div v-if="issue.name === 'aspect_ratio'" class="hstack gap-3 pb-1">
                <div v-if="issue.preview" :style="issue.preview.style">

                </div>
                <div>
                  Incorrect Aspect Ratio<br>
                  <small class="text-muted">{{ issue.description }}</small>
                </div>
              </div>
              <div v-else  class="overflow-text-ellipsis" >
                <div v-if="issue.name">{{ issue.name }}</div>
                <small class="text-muted overflow-text-ellipsis" v-linkify>
                  <http-status-code-badge v-if="issue.showStatusCode" :status-code="issue.statusCode"/>
                  {{ issue.description }}</small>
              </div>

              <template v-if="activeDeviceRender.deviceRenders.length === issue.devices.length">
                <small class="text-muted">
                  <router-link :to="{ query: {[id]: issue.id, 'i': 1}, force: true}"> <span
                      class="fa fa-magnifying-glass"></span> Locate on page
                  </router-link>
                  <small
                      v-if="issue.occurrences.length > 1">({{ issue.occurrences.length }}
                    occurrences)</small>
                </small>

              </template>
              <template v-else>
                <router-link v-for="device in issue.devices || []"
                             :to="{params: {deviceId: device.id}, query: {[id]: issue.id, 'i': 1}, force: true}"> <span

                    class="badge bg-secondary me-1" style="font-size:11px" title="Locate on page"><span
                    class="fa fa-magnifying-glass"></span> {{ device.name }}</span>
                </router-link>
              </template>
              <a v-if="issue.ignoreButton && issue.ignoreButton.show" style="float:right" href="" class="text-muted"
                 title="Ignore this issue"><span
                  class="far fa-trash-can" @click.prevent="ignoreIssue(issue)"></span></a>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.issue-selected {
  background-color: #e9ecef;
}

.issue-body {
  font-size: 90%;

  .accordion-body {
    padding: 6px;
    padding-top: 0;
  }
}

.issue-header {
  font-weight: 500
}

.issue-block {
  margin-bottom: 8px;
}

.issue-item {
  padding: 1rem;
  margin-top: -0px;
  margin-bottom: 8px;
  padding-top: 6px;
  padding-bottom: 2px;

}


</style>