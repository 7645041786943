<script setup>
import { storeToRefs } from "pinia";
import { computed, onMounted, watch } from "vue";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import { useRoute, useRouter } from "vue-router";
import HeatMap from "@/components/HeatMap.vue";

const router = useRouter();
const route = useRoute()
const activeDeviceRender = useActiveDeviceRenderStore();
const {historyData} = storeToRefs(activeDeviceRender)
const props = defineProps({
  devicePreview: Object,
});
watch(historyData, (historyData) => {
  highlightHistory()
})

const historyMarks = computed(() => {
  let result = [];
  let id = 0;
  if (activeDeviceRender.historyData) {

    for (let diff_mark of activeDeviceRender.historyData.diff_marks) {
      id += 1;
      let newText = diff_mark.box.text;
      if (!newText) {
        newText = '[resized element]'
      }
      result.push({
        id: id,
        type: diff_mark.type,
        node: diff_mark.box.node,
        oldText: diff_mark.parent.text,
        newText: newText,
      })
    }
  }
  return result;
})

function highlightHistory() {

  if (!props.devicePreview) {
    return;
  }
  let ratio = props.devicePreview.ratio();

  if (activeDeviceRender.historyData) {
    let newHighlights = []
    let newHighlightsParent = []
    let id = 0;
    for (let diff_mark of activeDeviceRender.historyData.diff_marks) {
      id += 1;
      let box = {
        left: diff_mark.box.left,
        top: diff_mark.box.top,
        width: diff_mark.box.width,
        height: diff_mark.box.height,
      }
      let parentBox = {
        left: diff_mark.parent.left,
        top: diff_mark.parent.top,
        width: diff_mark.parent.width,
        height: diff_mark.parent.height,
      }
      let q = {section: 'history', i: id, history: route.query.history}
      q['i'] = id;

      newHighlights.push({
        id: id,
        i: 1,
        box: box,
        query: q,
        'style': {
          'visibility': 'visible',
          'left': `${box.left * ratio}px`,
          'top': `${box.top * ratio}px`,
          'width': `${box.width * ratio}px`,
          'height': `${box.height * ratio}px`,
        },
        'variant': {
          'type': '',
          'style': 'box',
          'sentiment': 'positive',
          'color': 'red',
        },
      })
      if (diff_mark.parent && diff_mark.parent.left) {
        newHighlightsParent.push({
          id: `o_${id}`,
          box: parentBox,
          query: q,
          'style': {
            'visibility': 'visible',
            'left': `${parentBox.left * ratio}px`,
            'top': `${parentBox.top * ratio}px`,
            'width': `${parentBox.width * ratio}px`,
            'height': `${parentBox.height * ratio}px`,
          },
          'variant': {
            'type': '',
            'style': 'box',
            'sentiment': 'positive',
            'color': 'red',
          },
        })
      }
    }
    newHighlights.sort((a, b) => {
      if (a.box.top > b.box.top) {
        return 1
      }
      if (a.box.top < b.box.top) {
        return -1
      }
      return 0
    })
    props.devicePreview.showBoxes(newHighlights, newHighlightsParent)

    let index = parseInt(route.query.i, 10);
    let historyItem = historyMarks.value[index - 1]
    if (historyItem) {
      // highlightDetails.value.content.title = 'Update Found';
      // highlightDetails.value.content.text_1 = '';
      // highlightDetails.value.content.text_2 = '';
      // devicePreview.value.highlightEntries(historyItem.occurrences, {broken_link: historyItem.url})
      props.devicePreview.scrollToEntryIndex(index - 1)
    }
  }
}

const history = computed(() => {
  let result = [];
  for (let entry of activeDeviceRender.page.history) {
    if (!entry.device_renders) {
      continue;
    }
    result.push(entry.device_renders.find((item) => {
      return item.device === activeDeviceRender.deviceRender.deviceId
    }))
  }
  result.reverse()
  return result;
})
onMounted(() => {
  if (!route.query.history){
    let query = Object.assign({}, route.query);
    query['history'] = 1;
    router.replace({query: query})
  }

  props.devicePreview.showBoxes([])
});
</script>

<template>
  <BaseBlock class="mb-0 mt-4 infoblock"
             :title="activeDeviceRender.scanState === 'completed' ? 'Page History': 'Scan In Progress'"
             :headerBg="false">
    <div style="position:relative">
      <heat-map :history="history"></heat-map>
    </div>
    <template v-if="route.query.history && historyData">
      <span class="badge text-bg-warning mb-2 ms-3">History:</span>&nbsp;
      <template v-if="historyData.dateOld">
        <span class="badge bg-black-50">{{ $filters.dateFilter(historyData.dateOld) }}</span> -&gt;

        <span
            class="badge bg-black-50">{{ $filters.dateFilter(historyData.dateNew) }}</span>
      </template>
      <div v-if="historyData.diff_marks.length === 0" class="alert alert-info" role="alert">
        <template v-if="historyData.dateOld">
          No Changes Detected.
        </template>
        <template v-else>
          <h4 style="font-size:90%">{{ $filters.dateFilter(historyData.dateNew) }}:</h4> New Page Detected.
        </template>
      </div>
      <div v-else>
        <div class="accordion issue-block" style="position:relative;margin-top:6px"
             v-if="historyMarks.filter((h) => h.type === 'text').length">


          <div class="accordion-item">


            <h2 class="accordion-header">
              <button class="accordion-button issue-header" type="button" data-bs-toggle="collapse"
                      data-bs-target="#issue-panel-history-text"
              >
                {{ historyMarks.filter((h) => h.type === 'text').length }} Visible Text Changes

              </button>

            </h2>
            <div id="issue-panel-history-text" class="accordion-collapse issue-body collapse show"
                 data-bs-parent="#accordion-issue-list">
              <div class="accordion-body">

                <ul class="list-group list-group-flush ">
                  <div
                      class="list-group-item list-group-item-action rounded  issue-item"
                      v-for="historyMark in historyMarks.filter((h) => h.type === 'text')">
                    <div class="history-mark">
                      <span class="badge bg-black-50">&lt;{{ historyMark.node }}&gt;</span>
                      &nbsp;<span class="text-muted">  {{
                        $filters.truncate(historyMark.oldText, 20)
                      }} -&gt; {{ $filters.truncate(historyMark.newText, 20) }}</span>
                      <br><small class="text-muted">
                      <router-link
                          :to="{query: {'section': 'history', 'history': $route.query.history,  'i': historyMark.id}, force: true}"> <span
                          class="fa fa-magnifying-glass"></span> Locate on page
                      </router-link>
                    </small>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion issue-block" style="position:relative;margin-top:6px"
             v-if="historyMarks.filter((h) =>['difference', 'new'].includes(h.type)).length">


          <div class="accordion-item">


            <h2 class="accordion-header">
              <button class="accordion-button issue-header" type="button" data-bs-toggle="collapse"
                      data-bs-target="#issue-panel-history-changes"
              >
                {{ historyMarks.filter((h) => ['difference', 'new'].includes(h.type)).length }} Changed
                Elements

              </button>
            </h2>
            <div id="issue-panel-history-changes" class="accordion-collapse issue-body collapse show"
                 data-bs-parent="#accordion-issue-list">
              <div class="accordion-body">

                <ul class="list-group list-group-flush ">
                  <div
                      class="list-group-item list-group-item-action rounded  issue-item"
                      v-for="historyMark in historyMarks.filter((h) => ['difference', 'new'].includes(h.type))">
                    <div class="history-mark">
                      <span class="badge bg-black-50">&lt;{{ historyMark.node }}&gt;</span>
                      &nbsp;<span class="text-muted">  {{ $filters.truncate(historyMark.newText, 35) }}</span>
                      <br><small class="text-muted">
                      <router-link
                          :to="{query: {'section': 'history', 'history': $route.query.history,  'i': historyMark.id}, force: true}"> <span
                          class="fa fa-magnifying-glass"></span> Locate on page
                      </router-link>
                    </small>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

  </BaseBlock>
</template>

<style>


</style>