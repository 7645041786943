<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router'
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import axios from "axios";
import { storeToRefs } from "pinia";
import { useWebsiteStore } from "@/stores/website";
import Notifications from "@/components/Notifications.vue";
import { useUserStore } from "@/stores/user";
import LoadingPlaceholder from "@/components/LoadingPlaceholder.vue";

const activeDeviceRender = useActiveDeviceRenderStore();
const router = useRouter()
const route = useRoute()
let fromPath = route.meta.origin
let parentName = route.meta.parentName;
const websiteStore = useWebsiteStore();
const {website} = storeToRefs(websiteStore);
const userStore = useUserStore();

async function recheck(scope) {
  try {
    let r = await axios.post(`websites/${website.value.shortuuid}/recheck/`, {
      website: website.value.shortuuid,
      page_external_id: activeDeviceRender.page.externalId,
      scope: scope,
    })
    if (r.data.status === 'success') {
      //userStore.showNotification('Scan recheck initiated')
    } else {
      console.log(r)
    }
  } catch
      (e) {
    if (e.response.status >= 400) {
      if (e.response.data.code === 'insufficient_credits') {
        userStore.showNotification('credits')
      } else if (e.response.status === 401) {
        userStore.showNotification('login')
      }
    }
  }

}

onMounted(() => {
      var myModal = new bootstrap.Modal(document.getElementById('modal-block-tabs-altx'), {})
      var myModalEl = document.getElementById('modal-block-tabs-altx')
      myModalEl.addEventListener('hidden.bs.modal', function (event) {
        if (fromPath) {
          let path = route.fullPath;
          path = path.substring(0, path.indexOf('/preview/'))

          if (fromPath.includes(path)) {
            router.push(fromPath)
          } else {
            router.push(path)
          }

        }
      })
      myModal.show()

    }
)
onBeforeUnmount(() => {
  var myModalEl = document.getElementById('modal-block-tabs-altx')
  var modal = bootstrap.Modal.getInstance(myModalEl)

  if (myModalEl.classList.contains('show')) {
    fromPath = '';
    modal.hide()
  }
});

</script>

<template>

  <div
      class="modal load-transition"
      id="modal-block-tabs-altx"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-block-tabs-alt"
      aria-hidden="true"
  >
    <div v-show="!activeDeviceRender.loaded" style="position:absolute;top:0%;left:50%">
      <div class="loading-container">

    <span class="spinner-border text-primary ms-2 me-2 text-muted"
          style="font-size:18px;height:28px;width:28px;color:white !important;">      </span>
      </div>
    </div>
    <div v-show="activeDeviceRender.loaded"
         class="modal-dialog load-transition forced-large-modal modal-xl"
         role="document">
      <div id="preview-modal" class="modal-content load-transition " style="background-color:#EBEEF2">
        <BaseBlock transparent class="mb-0 " style="height:100%">
          <template #content>
            <div class="" role="tablist">
            </div>

            <div class="row justify-content-end">
              <div class="col-12 col-md-6 col-xl-7 d-none d-md-block">
                <div class=" w-100 mt-2 text-center ms-4 ">
                  <button
                      v-if="activeDeviceRender.page && ['completed', 'error'].includes(activeDeviceRender.page.status) "
                      v-tooltip="`Check Again`" class="btn btn-sm" @click="recheck('page')"><span
                      class="fa fa-redo"></span></button>
                  <button
                      v-else-if="activeDeviceRender.page && ['pending', 'in_progress'].includes(activeDeviceRender.page.status)"
                      v-tooltip="`Scan in progress`" class="btn btn-sm">
                    <div class="spinner-border"
                         style="font-size:8px;color:#2F495E;width:14px;height:14px;">
                      <span class="sr-only">Loading..</span>
                    </div>

                  </button>
                  <div class="d-inline " v-if="activeDeviceRender.page">
                    <a target="_blank" :href="activeDeviceRender.page.url" v-tooltip="`External link`"
                       class="external-link-plain">{{ activeDeviceRender.page.url }}</a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-5">
                <ul class="nav nav-tabs nav-tabs-alt">
                  <li class="nav-item">
                    <router-link :to="{query: {}}" :class="{'nav-link': true, 'active': !$route.query.section}"
                                 aria-current="page" active-class="aa" exact-active-class="aa">Audit
                    </router-link>
                  </li>
                  <li class="nav-item me-auto">
                    <router-link :to="{query: {section: 'ai'}}"
                                 :class="{'nav-link': true, 'active': $route.query.section === 'ai'}"
                                 active-class="aa"
                                 exact-active-class="aa">Insights
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{query: {section: 'performance'}}"
                                 title="Performance"
                                 :class="{'nav-link': true, 'active': $route.query.section === 'performance'}"
                                 active-class="aa" exact-active-class="aa">
                      <span class="fa fa-rocket"></span>
                    </router-link>
                  </li>
                  <li>

                    <router-link :to="{query: {section: 'history'}}"
                                 title="History"
                                 :class="{'nav-link': true, 'active': $route.query.section === 'history'}"
                                 active-class="aa" exact-active-class="aa">
                      <span class="si si-speedometer"></span>
                    </router-link>

                  </li>
                  <li class="nav-item mt-2">

                  </li>

                  <li class="nav-item ">
                    <button
                        type="button"
                        class="btn-block-option mt-2 me-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                      <i class="fa fa-fw fa-times"></i>
                    </button>

                  </li>
                </ul>
              </div>
            </div>


            <div class="block-content tab-content  "
                 :class="{'block-content-performance': route.name === `preview-performance-${parentName}`}"
                 style="height:100%;padding:0;margin:0">
              <div
                  class="tab-pane active preview-body"
                  id="btabs-alt-static-home"
                  role="tabpanel"
                  aria-labelledby="btabs-alt-static-home-tab"
                  tabindex="0"
              >
                <router-view></router-view>
              </div>
            </div>
          </template>

        </BaseBlock>
      </div>
    </div>
  </div>

</template>
<style>
.block-content-performance {
  padding: 0px;
}

.modal-plain-height {
  height: 200px;
}

@media only screen and (min-width: 768px) {
  .forced-large-modal {
    min-width: 740px
  }
}

@media only screen and (max-width: 1199px) and  (min-width: 768px) {

  .block-content {
    padding-right: 4px;
  }
}

.modal-full-height {
  height: 95vh;
  overflow: hidden;
}


.preview-body {
  .accordion-button {
    background-color: white;
  }

  .accordion-button:not(.collapsed) {
    background-color: #fcfcff; /* color of the track */
    box-shadow: none;

  }

  .accordion-button::after {
    scale: 0.8;
  }

  .accordion-item {
    border: 0;
  }

  .accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
  }
}
</style>