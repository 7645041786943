<script setup>
import { computed, onMounted } from "vue";
import GooglyEyes from "@/components/GooglyEyes.vue";
import { useRoute } from "vue-router";
import { useAuth } from "@/composables/auth";

const {loggedIn} = useAuth()
const route = useRoute();
// Remove side transitions on window resizing
onMounted(() => {

});
</script>

<template>
  <!-- Page Loader -->
  <div v-if="!route.meta.hideNav" class="row g-0 justify-content-center text-center">
    <div class="col-md-8">
      <nav class="navbar navbar-expand-md">
        <div class="container-fluid">
          <router-link class="navbar-brand" :to="{name: 'landing'}">
            <googly-eyes></googly-eyes>
          </router-link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">


            </ul>
            <ul class="navbar-nav ">

              <li class="nav-item">
                <a class="nav-link" href="#pricing">Pricing</a>
              </li>
              <template v-if="loggedIn">
                <li class="nav-item ms-2">
                  <router-link class="nav-link" aria-current="page" :to="{name: 'accountDashboard'}">
                    Dashboard
                  </router-link>
                </li>
              </template>
              <template v-else>
                <li class="nav-item ms-2">
                  <router-link class="nav-link" aria-current="page" :to="{name: 'auth-login'}">Log In</router-link>
                </li>
                <li class="nav-item ms-2">
                  <router-link class="btn btn-primary" aria-current="page" :to="{name: 'auth-signup'}">Sign Up
                  </router-link>
                </li>
              </template>

            </ul>

          </div>
        </div>
      </nav>
    </div>
  </div>

  <!-- Main Container -->
  <div>
    <slot name="page-top-content"></slot>
    <RouterView/>
  </div>
  <!-- END Main Container -->
</template>

<style>

</style>