<script setup>
// Component properties
import Header from "@/layouts/partials/Header.vue";
import { useAuth } from "@/composables/auth";
import { useRoute } from "vue-router";
const auth = useAuth()
const route = useRoute();
defineProps({
  title: {
    type: String,
    description: "The title of page heading section",
  },
});

async function logout() {
  await auth.logout()
  return await router.push({name: 'auth-login'})
}
</script>

<template>
<header id="page-header">
    <slot>
      <!-- Header Content -->
      <div class="content-header">
        <slot name="content">
          <div class="d-flex align-items-center">
            <slot name="content-left">
              <RouterLink v-if="route.name !== 'backend-dashboard'"
                  :to="{ name: 'backend-dashboard' }"
                  class="btn btn-sm btn-alt-info d-flex align-items-center"
              >

                <i
                    class="fa fa-backward"
                ></i>
                <span class="d-none d-sm-inline-block ms-2">Admin Home</span>

              </RouterLink>
            </slot>
          </div>
          <!-- Right Section -->
          <div class="d-flex align-items-center">
            <slot name="content-right">



              <!-- User Dropdown -->
              <div class="dropdown d-inline-block ms-2">
                <button
                    type="button"
                    class="btn btn-sm btn-alt-secondary d-flex align-items-center"
                    id="page-header-user-dropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  <i
                      class="fa fa-gears"
                  ></i>
                  <span class="d-none d-sm-inline-block ms-2">Admin</span>
                  <i
                      class="fa fa-fw fa-angle-down d-none d-sm-inline-block opacity-50 ms-1 mt-1"
                  ></i>
                </button>

                <div
                    class="dropdown-menu fs-sm"
                >
                  <router-link :to="{name: 'backend-spelling'}" class="dropdown-item">Spelling</router-link>
                  <div role="separator" class="dropdown-divider m-0"></div>
                  <router-link :to="{name: 'accountDashboard'}" class="dropdown-item">My Account</router-link>

                  <a href="#" @click.prevent="logout" class="dropdown-item">Log Out</a>

                </div>
              </div>
              <!-- END User Dropdown -->

            </slot>
          </div>
          <!-- END Right Section -->
        </slot>
      </div>
      <!-- END Header Content -->


    </slot>
  </header>

</template>
