<script setup>
import RadialProgress from "vue3-radial-progress";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";

const activeDeviceRender = useActiveDeviceRenderStore();
import { computed } from "vue";
let performanceScore = computed(() =>{
  if (activeDeviceRender.page.lighthouse && activeDeviceRender.page.lighthouse.performance_score) {
    let p = activeDeviceRender.page.lighthouse.performance_score;
    // round to integer
    return Math.round(p * 100);
  }
  return ''

})

let progressColor = computed(() =>{
  if (performanceScore.value >= 90) {
    return 'green'
  }
  if (performanceScore.value >= 50) {
    return 'orange'
  }
  return 'red'

})
</script>

<template>
             <div class="accordion  issue-block position-relative">
                  <div class="accordion-item" >
                    <h2 class="accordion-header">
                      <button class="accordion-button issue-header collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#issue-panel-performance"
                      >
                        Performance Issues
                      </button>
                    </h2>
                    <div id="issue-panel-performance" class="accordion-collapse collapse issue-body"
                         data-bs-parent="#accordion-issue-list">
                      <div class="accordion-body">
<div class="row">
  <div class="col-2">
     <RadialProgress style=""
                    :diameter="58"
                    innerStrokeColor="gray"
                    :startColor="progressColor"
                    :stopColor="progressColor"
                    :strokeWidth="6"
                    :innerStrokeWidth="4"
                    :completed-steps="70"
                    :total-steps="100">
{{performanceScore}}
    </RadialProgress>
  </div>
  <div class="col-10">
  The page performance could be improved.
    <br>
      <router-link :to="{query: {section: 'performance'}}">Show Performance Details</router-link>

</div>
</div>
                      </div>
                    </div>
                  </div>
                </div>
</template>

<style scoped>

</style>