<script setup>
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute()
const props = defineProps({
  devicePreview: Object,
  highlightDetails: Object,
});

const scrollFunction = () =>{
}

function showHighlightDetails() {
  let query = route.query;
  let index = parseInt(query.i, 10) || 0;
  props.highlightDetails.style.visibility = 'visible'
  props.highlightDetails.style.top = '100px'
  let el = document.querySelector(`#highlight-box-${index}`);
  let highlightDetailsBoxEl = document.querySelector(`#highlight-details-box`);
  let highlightDetailsBoxParentEl = document.querySelector(`#preview-modal`);
  if (el) {
    let rect = el.getBoundingClientRect();
    let highlightDetailsBoxRect = highlightDetailsBoxEl.getBoundingClientRect();
    let highlightDetailsBoxParentRect = highlightDetailsBoxParentEl.getBoundingClientRect();
    let top = rect.top - highlightDetailsBoxParentRect.top - highlightDetailsBoxRect.height;
    top = Math.max(top, 0)
    top = Math.min(top, 800 - highlightDetailsBoxRect.height)
    props.highlightDetails.style.top = `${top}px`

    let left = rect.left - highlightDetailsBoxParentRect.left + rect.width / 2 - highlightDetailsBoxRect.width / 2;
    left = Math.max(left, 0)
    left = Math.min(left, 600 - highlightDetailsBoxRect.width / 2)
    props.highlightDetails.style.left = `${left}px`
  }
}

const activeDeviceRender = useActiveDeviceRenderStore();

defineExpose({
  scrollFunction, showHighlightDetails
});
</script>

<template>
 <div id="highlight-details-box" :style="highlightDetails.style">

                  <div class="issue-alert  alert alert-dismissible show fade" role="alert"
                       style="padding:16px;padding-top:8px;">
                    <router-link :to="{query: null}">
                <span class="fa fa-close"
                      style="float:right;padding-left:16px;font-size:16px;margin-top:2px;margin-right:2px;color:white;opacity:0.8"></span>
                    </router-link>
                    <strong v-html="highlightDetails.content.title"></strong><br>
                    <span style="font-size:96%" v-html="highlightDetails.content.text_1"></span>
                    <div style="font-size:96%" v-html="highlightDetails.content.text_2"></div>
                    <template v-if="0">
                      <div style="padding-top:4px">Found in image alt tag:</div>
                      &lt;image alt=<span
                        style="text-decoration:underline;text-decoration-style: wavy;text-decoration-color: white">"laons"..</span>
                    </template>

                    <div style="position: absolute;bottom:2px;left:18px" v-if="highlightDetails.prevLink.show">
                      <router-link :style="highlightDetails.prevLink.style" title="Previous issue"
                                   :to="{query: highlightDetails.prevLink.query}"><span
                          class="fa fa-arrow-left"></span></router-link>
                    </div>
                    <div style="position: absolute;bottom:-18px;left:calc(50% - 10px)">
                      <span class="fa fa-caret-down" style="font-size: 30px;color:red"></span>
                    </div>
                    <div style="position: absolute;bottom:2px;right:18px;" v-if="highlightDetails.nextLink.show">
                      <router-link :style="highlightDetails.nextLink.style" title="Next issue"
                                   :to="{ query: highlightDetails.nextLink.query}"><span
                          class="fa fa-arrow-right"></span></router-link>
                    </div>
                    <div v-if="highlightDetails.content.showContext"
                         class="issue-alert issue-alert-code max-height-overflow alert alert-dismissible show fade mb-2">
                     {{ highlightDetails.content.context }}
                    </div>
                  </div>

                </div>

</template>

<style scoped>

</style>