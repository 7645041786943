import { defineStore } from 'pinia'
import { ref, watch } from "vue";
import { gql } from "graphql-tag";
import { useQuery, useSubscription } from "@vue/apollo-composable";
import { useRoute, useRouter } from "vue-router";
import { useAuth } from "@/composables/auth"
import { EventBus } from "@/events/eventBus";

export const useUserStore = defineStore('user', () => {
    const user = ref(null);
    const loaded = ref(false);
    const route = useRoute();
    const router = useRouter();

    const auth = useAuth()

    const SELECT_USERS = gql`
        query Users {
          users_user{
              id
              email
              role
              available_credits
              websites(where: {parent_id: {_is_null: true}, deleted: { _eq: false}}, limit: 5000, order_by: { last_full_audit: desc } ){
                id
                shortuuid
                url
                last_full_audit
              }
              collaborators{
                id
                role
                websites_website{
                  id
                  shortuuid
                  url
                }
             }
          }
        }`

    const {result, onError, refetch} = useQuery(SELECT_USERS, null, () => ({
            enabled: route.path !== '/' && !route.meta.disableAuth
        })
    )
    watch(
        result,
        data => {
            loaded.value = true
            user.value = {
                id: data.users_user[0].id,
                email: data.users_user[0].email,
                role: data.users_user[0].role,
                availableCredits: data.users_user[0].available_credits,
                websites: data.users_user[0].websites.map((website) => ({
                    id: website.id,
                    shortuuid: website.shortuuid,
                    url: website.url,
                    lastFullAudit: website.last_full_audit
                })),
                collaboratorSites: data.users_user[0].collaborators.map((collaborator) => ({
                    id: collaborator.websites_website.id,
                    role: collaborator.websites_website.role,
                    url: collaborator.websites_website.url
                }))
            }
            if (user.role === 'user') {
                selectUserSubscriptionActive.value.active = true;
            }
        },
        {
            lazy: true // Don't immediately execute handler
        }
    )

    onError(async (error) => {
        // navigate to login if not already there
        if (error.graphQLErrors[0].extensions.code === 'access-denied' && route.path !== '/login') {
            await auth.logout()
        }
    })

    const SELECT_USER_SUBSCRIPTION = gql`
        subscription Users{
          users_user{
              id
              available_credits
          }
        }`
    const selectUserSubscriptionActive = ref({
        active: false,
    })
    const {result: userSubscriptionResult} = useSubscription(SELECT_USER_SUBSCRIPTION, {}, () => ({
            enabled: selectUserSubscriptionActive.value,
        })
    )
    watch(
        userSubscriptionResult,
        async data => {
            if (refetch) {
                await refetch()
            }
        },
    )

    function showNotification(type='success', message='') {
        const notification = {
            message: message,
            type: type
        }
         EventBus.emit('showNotification', notification);
    }


    return {loaded, user, refetch, showNotification}
})