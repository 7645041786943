<script setup>
import { ref, computed, reactive, watch, onMounted, provide } from "vue";
import RadialProgress from "vue3-radial-progress";

const completedSteps = ref(5);
const totalSteps = ref(100);
const route = useRoute()
setInterval(() => {
  completedSteps.value += (100 - completedSteps.value) * 0.01
}, 500);

const imageRoot = import.meta.env.VITE_IMAGE_ROOT

import devices from "@/data/devices";
import { useRoute } from "vue-router";

const props = defineProps({
  id: Number,
  deviceId: String,
  deviceName: {type: String, default: ""},
  status: {type: String, default: ""},
  errorReason: {type: String, default: "abc"},
  screenshot: {type: Object, default: {}},
  disableScreenshotMain: {type: Boolean, default: false},
  fadeScreenshot: {type: Boolean, default: false},
  screenshotFull: {type: Object, default: {}},
  history: {
    type: Object, default: {
      show: false,
      screenshotsNew: {},
      screenshotsOld: {},
    }
  },
  maxHeight: {type: Number, default: 260},
  maxWidth: {type: Number, default: 600},
})
let device = ref(devices[props.deviceId]);
const mockupHeight = ref(props.maxHeight);
const scale = computed(() => {
  return mockupHeight.value / device.value.preview.mockup.height;
});

const newScreenshotFull = computed(() => {
  if (props.history.show && props.history.screenshotsNew.full) {
    return props.history.screenshotsNew.full;
  }
  return props.screenshotFull;
})
const mockupWidth = ref(mockupHeight.value * device.value.preview.mockup.width / device.value.preview.mockup.height)
const screenshotWidth = ref(100)
const screenshotHeight = ref(10)
const screenshotMainVisible = ref(!props.disableScreenshotMain);
const screenshotFullLoaded = computed(() => {
  let screenshot = `${newScreenshotFull.value.url}?tr=w-${screenshotWidth.value}`
  return loadedScreenshots.value.includes(screenshot);
})
const screenshotMainLoaded = computed(() => {
  let s = `${props.screenshot.url}?tr=w-${screenshotWidth.value}`
  return loadedScreenshots.value.includes(s);
})

const loadedScreenshots = ref([]);
watch(props, (value) => {
      device.value = devices[props.deviceId];
      mockupHeight.value = Math.round(Math.min(props.maxHeight, props.maxWidth / (device.value.preview.mockup.width / device.value.preview.mockup.height)))
      mockupWidth.value = Math.round(mockupHeight.value * device.value.preview.mockup.width / device.value.preview.mockup.height);
      screenshotWidth.value = Math.round(device.value.preview.screenshot.width * scale.value);
      screenshotHeight.value = Math.round(device.value.preview.screenshot.height * scale.value);

      // if the screenshot is already in the DOM then it wont trigger the load event,
      // so we set it as loaded
      let newLoadedScreenshots = [];
      if (loadedScreenshots.value.includes(`${props.screenshot.url}?tr=w-${screenshotWidth.value}`)) {
        newLoadedScreenshots.push(`${props.screenshot.url}?tr=w-${screenshotWidth.value}`);
      }
      if (loadedScreenshots.value.includes(`${newScreenshotFull.value.url}?tr=w-${screenshotWidth.value}`)) {
        newLoadedScreenshots.push(`${newScreenshotFull.value.url}?tr=w-${screenshotWidth.value}`);
      }
      loadedScreenshots.value = newLoadedScreenshots;

      setTimeout(() => {
        if (document.getElementById(`scrollcontent-${props.id}`)) {
          screenshotMainVisible.value = document.getElementById(`scrollcontent-${props.id}`).scrollTop === 0 && !props.disableScreenshotMain;
        }
      }, 0)


    },
    {immediate: true}
)

function handleLoad(screenshot, emitEvent = false) {
  if (!loadedScreenshots.value.includes(screenshot)) {
    loadedScreenshots.value.push(screenshot);
  }
  if (emitEvent) {
    emit('loaded', {screenshotWidth: screenshotWidth.value})
  }
}

let scrollAnimationInProgress = false;
let emitOnScrollToEntryCompleted = false;

function checkScroll(el) {
  screenshotMainVisible.value = el.target.scrollTop === 0 && !props.disableScreenshotMain;
  if (!scrollAnimationInProgress) {
    emit('onManualScroll', el.target.scrollTop);
  }
}

function smoothScroll(targetPosition, duration) {
  if (!newScreenshotFull.value.url) {
    return;
  }
  targetPosition = Math.max(0, targetPosition);
  const container = document.querySelector(`#scrollcontent-${props.id}`);
  const startPosition = container.scrollTop;
  let startTime = null;
  scrollAnimationInProgress = true;

  function animation(currentTime) {
    if (!startTime) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const progress = ease(timeElapsed, startPosition, targetPosition - startPosition, duration);

    container.scrollTop = progress;
    if (timeElapsed < duration && Math.floor(targetPosition) !== container.scrollTop) {
      requestAnimationFrame(animation);
    } else {
      setTimeout(() => {
        scrollAnimationInProgress = false;
        container.scrollTop = targetPosition;
        if (emitOnScrollToEntryCompleted) {
          emit('onScrollToEntryCompleted');
        }
      }, 100);

    }

  }

  // Easing function (easeInOutQuad)
  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  requestAnimationFrame(animation);
}

const highlightBoxes = ref([])
const oldHighlightBoxes = ref([])

function showBoxes(boxes, oldBoxes = null) {
  highlightBoxes.value = boxes;
  oldHighlightBoxes.value = oldBoxes;
}

function highlightBox(boxIndex) {
  for (let box of highlightBoxes.value) {
    box.highlight = false;
  }
  let box = highlightBoxes.value[boxIndex];
  if (!box) {
    return
  }
  highlightBoxes.value[boxIndex].highlight = true;
}

function scrollToBox(box) {
  if (!newScreenshotFull.value.url) {
    return;
  }

  emitOnScrollToEntryCompleted = true;
  let issueBox = {};
  Object.assign(issueBox, box);
  let ratio = screenshotWidth.value / newScreenshotFull.value.width;
  smoothScroll(ratio * issueBox.top - ratio * props.screenshot.height * 0.4 + ratio * issueBox.height * 0.5, 500)
}

function scrollToEntryIndex(index, duration = 500) {
  if (!newScreenshotFull.value.url) {
    return;
  }
  if (index < 0 || index >= highlightBoxes.value.length) {
    return;
  }

  emitOnScrollToEntryCompleted = true;
  let box = {};
  Object.assign(box, highlightBoxes.value[index].box);
  let ratio = screenshotWidth.value / newScreenshotFull.value.width;
  smoothScroll(ratio * box.top - ratio * props.screenshot.height * 0.4 + ratio * box.height * 0.5, duration)
}

function scroll(dir = 'down', amount=0.8, duration=200) {
  const container = document.querySelector(`#scrollcontent-${props.id}`);
  const startPosition = container.scrollTop;
  let ratio = screenshotWidth.value / newScreenshotFull.value.width;
  if (dir === 'up') {
    ratio = -ratio;
  }
  emitOnScrollToEntryCompleted = false;
  smoothScroll(startPosition + ratio * props.screenshot.height * amount, duration)
}

function logClick(event) {
  event.stopPropagation();
}

const dragX = ref(100);
const dragging = ref(false);
let startDragX = 0;

function mouseMove(event) {
  if (!dragging.value) {
    return;
  }
  dragX.value = event.clientX - startDragX;
  event.preventDefault();
  event.stopPropagation();
}

function startDrag(event) {
  startDragX = event.clientX - dragX.value;
  dragging.value = true;
  document.addEventListener('mouseup', handleGlobalMouseUp);
}

function handleGlobalMouseUp() {
  dragging.value = false;
  document.removeEventListener('mouseup', handleGlobalMouseUp);
}

const mouseOverIndex = ref(-1);

function highlightBoxMouseOver(highlightBox, over = true) {

  for (let box of highlightBoxes.value) {
    box.highlight = false;
  }

  if (over) {
    highlightBox.highlight = true;
    mouseOverIndex.value = highlightBox.i;
  } else {
    mouseOverIndex.value = -1;
  }

  emit('onMouseOverHighlight', 123);
  // highlightBox.sentiment = highlightBox.variant.sentiment;
}

function ratio() {
  return screenshotWidth.value / newScreenshotFull.value.width
}
 function handleWheel(event) {
  event.preventDefault()
 }
const emit = defineEmits(['loaded', 'onManualScroll', 'onScrollToEntryCompleted', 'onMouseOverHighlight'])
defineExpose({
  scroll, scrollToEntryIndex, highlightBox, mouseOverIndex, showBoxes, ratio, scrollToBox
});
</script>

<template>
  <div class="device-mockup"
       :style="{ 'width': `${Math.round(mockupWidth)}px`,  'height': `${Math.round(mockupHeight)}px`, }">

    <img :src="`${imageRoot}images/devices/${device.os}/${device.id}/${device.id}.png?tr=w-${mockupWidth}`"
         alt="Mockup"
         class="mockup-image"
         :height="mockupHeight"
         :width="mockupWidth"
         draggable="false"
    >

    <img v-if="device.preview.header.visible"
         :src="`${imageRoot}images/devices/${device.os}/${device.id}/header.png?tr=w-${Math.round(scale*device.preview.header.width)}`"
         alt="Mobile Screenshot"
         class="header-image"
         :style="{
          'top': `${scale*device.preview.header.top}px`,
          'left': `${scale*device.preview.header.left}px`,
          'width': `${scale*device.preview.header.width}px`,
         }"
         draggable="false"
    >
    <img v-if="device.preview.footer.visible"
         :src="`${imageRoot}images/devices/${device.os}/${device.id}/footer.png?tr=w-${Math.round(scale*device.preview.footer.width)}`"
         alt="Mobile Screenshot"
         class="footer-image"
         :style="{
          'top': `${scale*device.preview.footer.top}px`,
          'left': `${scale*device.preview.footer.left}px`,
          'width': `${scale*device.preview.footer.width}px`,
         }"
         draggable="false"
    >
    <div v-if="newScreenshotFull.error || screenshot.error || status === 'error'"
         class="d-flex justify-content-center align-items-center"
         style="position: relative;bottom:60%">
      <div style="padding:5px;display: inline-block;position:absolute;z-index:99;max-width: 80%;white-space: normal">
        <div class="alert alert-danger" role="alert" v-if="maxHeight > 500">
          <template v-if="['500', '501', '502'].includes(errorReason)">
            <span class="fa fa-warning"></span> <b>Unable to load page.</b>
            <div style="font-size: 90%;padding:5px">
              <p>
                The server responded with a {{ errorReason }} error.
              </p>
              <p>
                Please make sure the site is up and running, and try again.
              </p>
            </div>
          </template>
          <template v-else>
            <span class="fa fa-warning"></span> <b>Unable to render screenshot.</b>
            <div style="font-size: 90%;padding:5px">
              <p>
                This is normally caused by temporary network issues between the site and our servers, or the page taking
                too long to load.

              </p>
              <p>
                If the issue persists, please contact us.
              </p>
            </div>
          </template>
        </div>
        <div v-else>
          <span class="fa fa-warning" style="color:red;font-size:32px"></span>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center" style="position: relative;bottom:60%">
      <div class="loader" v-if="newScreenshotFull.url" style="display: inline-block;position:absolute;">
        <span class="sr-only">Loading...</span>
        <br>
      </div>

      <div class="text-muted waiting-box" :class="{ 'waiting-box-partial': screenshotWidth > 200}"
           v-show="!newScreenshotFull.url">
        <div class="d-flex justify-content-center align-items-center;">
          <RadialProgress
              :diameter="68"
              :strokeWidth="6"
              :innerStrokeWidth="4"
              :completed-steps="completedSteps"
              :total-steps="totalSteps">
            <div class="spinner-border"
                 style="font-size:2px;color:#2F495E;position:absolute;left:2px;top:6px;width:56px;height:56px;">
              <span class="sr-only">Loading..</span>
            </div>
            <span style="background-color:#EBE9E9;border-radius: 8px">{{ Math.floor(completedSteps) }}%</span>
          </RadialProgress>
        </div>
        <span class="badge text-bg-info me-1" style="font-size:11px">Waiting for Page..</span>
      </div>
    </div>
    <div v-show="history.show"
         @mousedown="startDrag"
         :style="{
            'position': 'absolute',
            'cursor': 'col-resize',
            'left': `${scale*device.preview.screenshot.marginLeft+dragX}px`,
            'height': `${screenshotHeight}px`,
            'width': '5px',
            'background-color': 'red',
            'top': `${scale*device.preview.screenshot.marginTop}px`,
            'z-index': '999',
      }">
      <div style="position:absolute;top:48%;left:10px">
        <span style="font-size:40px;color:red" class="fa fa-angle-right"></span>
      </div>
      <div style="position:absolute;top:48%;left:-28px">
        <span style="font-size:40px;color:red" class="fa fa-angle-left"></span>
      </div>
    </div>

    <div :id="`scrollcontent-${props.id}`"
         @mousemove="mouseMove"
         :class="{
      'scrollable-content': 1,
      'scrollable-content-visible': device.preview.scrollbars.visible,
      'scrollable-content-hidden': !screenshotMainLoaded || !device.preview.scrollbars.visible,
    }" @scroll.prevent="checkScroll"
         :style="{
      'top': `${scale*device.preview.screenshot.marginTop}px`,
      'left': `${scale*device.preview.screenshot.marginLeft}px`,
      'height': `${screenshotHeight}px`,
      'width': `${mockupWidth - scale*device.preview.screenshot.marginLeft*2}px`,
      'overflow-y': screenshotFullLoaded ? 'scroll': 'hidden',
      'opacity': screenshotFullLoaded ? '1': '0.2',
    }"
    >
<div v-if="props.fadeScreenshot" @wheel.stop.prevent="handleWheel" class="position-absolute" style="width:100%;height:100%;background-color:white;z-index: 9999;opacity:0.7"></div>
      <div class="screenshot-image" v-show="history.show"
           style="z-index:2;" :style="{
             'clip-path': `inset(0px ${screenshotWidth-dragX}px 0px 0px)`
           }"
      >

        <img v-if="history.screenshotsOld.full && history.screenshotsOld.full.url"
             :src="`${history.screenshotsOld.full.url}?tr=w-${screenshotWidth}`"
             :width="screenshotWidth" draggable="false"
        >
        <router-link
            :to="{params: {pageId: $route.params.pageId, deviceId: deviceId}, query: highlightBox.query, force: true}"
            v-for="highlightBox in oldHighlightBoxes || []" :key="highlightBox.id"
            :class="{'highlight-box': !$route.query.history, 'active-animation animation-active': $route.query.history}"
            :style="highlightBox.style"
        >
        </router-link>

      </div>

      <div class="screenshot-image">

        <img v-if="newScreenshotFull.url" v-show="screenshotFullLoaded"
             :src="`${newScreenshotFull.url}?tr=w-${screenshotWidth}`"
             alt="Mobile Screenshot"
             @load="handleLoad(`${newScreenshotFull.url}?tr=w-${screenshotWidth}`, true)"
             :width="screenshotWidth"
             draggable="false"
        >
        <img v-if="screenshot.url" v-show="screenshotMainLoaded && screenshotMainVisible &&!history.show"
             :src="`${screenshot.url}?tr=w-${screenshotWidth}`"
             alt="Mobile Screenshot"
             @load="handleLoad(`${screenshot.url}?tr=w-${screenshotWidth}`)"
             :width="screenshotWidth" style="position:absolute;top:0;left:0"
             draggable="false"
        >
        <router-link :id="highlightBox.id"
                     :to="{params: {pageId: $route.params.pageId || highlightBox.pageId}, name: highlightBox.routeName || $route.name, query: highlightBox.query, force: true}"
                     v-for="highlightBox in highlightBoxes || []" :key="highlightBox.key"
                     @mouseenter="highlightBoxMouseOver(highlightBox)"
                     @mouseleave="highlightBoxMouseOver(highlightBox, false)"
                     :style="highlightBox.style"
                     :class="{
                       'highlight-box-positive': highlightBox.variant.sentiment ===  'positive',
                       'highlight-box-negative': highlightBox.variant.sentiment ===  'negative',
                       'animation-active-high': highlightBox.priority ===  'high',
                       'animation-active-medium': highlightBox.priority ===  'medium',
                       'animation-active-low': highlightBox.priority ===  'low',
                       'highlight-box-selected': highlightBox.highlight || highlightBox.active,
                       'highlight-box': !$route.query.history,
                       'active-animation': highlightBox.variant.type === 'box' || $route.query.history
                     }"

        >
          <RoughNotation v-if="highlightBox.variant.type === 'rough'" :is-show="highlightBoxes.length > 0"
                      :color="highlightBox.variant.color" :strokeWidth="2" type="box">
            <div :style="highlightBox.style"></div>
          </RoughNotation>
          <div v-if="highlightBox.label" style="position:absolute;top:-28px;right:0px;">{{ highlightBox.label }}</div>
        </router-link>

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.device-mockup {
  position: relative;
  display: inline-block;
}

.mockup-image, .screenshot-image, .header-image, .footer-image {
  display: block;
}

.mockup-image {
  position: relative;
  z-index: 3;
  pointer-events: none;
  border-radius: 5px;
}

.screenshot-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* places the screenshot above the mockup */
  height: auto;
}

.highlight-box {
  position: absolute;
  visibility: hidden;
  z-index: 20;
  transition: background-color 0.4s;
  //border: 3px dashed red;
}

.highlight-box-selected.highlight-box-positive {
  background-color: rgba(00, 128, 0, .2);
  outline: 6px solid rgba(00, 128, 0, .2);
}

.highlight-box-selected.highlight-box-negative {
  background-color: rgba(255, 0, 0, 0.29);
  outline: 6px solid rgba(255, 0, 0, 0.29);

}

.highlight-box-priority-high {
  border: 2px dotted red;
  opacity: 0.5;
}

.highlight-box-priority-medium {
  border: 2px dotted orange;
  opacity: 0.5;
}

.highlight-box-priority-low {
  border: 2px dotted #ffd500;
  opacity: 0.5;
}

.highlight-box-priority-high.highlight-box-selected {
  border: 3px dashed red;
  opacity: 1;
}

.highlight-box-priority-medium.highlight-box-selected {
  border: 3px dashed orange;
  opacity: 1;
}

.highlight-box-priority-low.highlight-box-selected {
  border: 3px dashed #ffd500;
  opacity: 1;
}

.screenshot-image-full {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* places the screenshot above the mockup */
  height: auto;
}

.header-image {
  position: absolute;
  z-index: 2;
  height: auto;
}

.footer-image {
  position: absolute;
  z-index: 2;
  height: auto;
}

.scrollable-content {
  position: absolute;
  overflow-y: scroll; /* makes it vertically scrollable */
  overflow-x: hidden;
  z-index: 1;
  scrollbar-width: none; /* Firefox */
  background-color: #9a9292;
}

/* For Webkit browsers (e.g., Chrome, Safari) */
.scrollable-content-visible::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
}

.scrollable-content-visible::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scroll thumb */
  border-radius: 2px; /* roundness of the scroll thumb */
}

.scrollable-content-visible::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* color of the scroll thumb on hover */
}

.scrollable-content-visible::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* color of the track */
}

/* For Firefox */
.scrollable-content-visible {
  scrollbar-width: thin; /* makes the scrollbar thinner */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}

.scrollable-content-hidden {
  width: 105%; /* might have to be dynamic in case of layout errors display */
}

.scrollable-content-hidden::-webkit-scrollbar {
  width: 0;
  background: transparent; /* Chrome, Safari, newer versions of Opera */
}

.loader {
  width: 4.8px;
  height: 4.8px;
  display: block;
  margin: 20px auto;
  position: relative;
  border-radius: 4px;
  color: #949494;
  background: currentColor;
  box-sizing: border-box;
  animation: animloader 0.3s 0.3s linear infinite alternate;
}

.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 4.8px;
  height: 4.8px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
  animation: animloader 0.3s 0.45s linear infinite alternate;
}

.loader::after {
  top: -15px;
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    width: 4.8px
  }
  100% {
    width: 48px
  }
}

.waiting-box {
  display: inline-block;
  position: absolute;
  z-index: 99;
  opacity: 1;

  padding: 16px;
  padding-right: 12px;
  border-radius: 5px
}

.waiting-box-partial {
  background-color: #f1f1f1;
}


.changeBox {
  /*background-color: rgba(255, 0, 0, 0.09);*/
  border: 1px solid red;
}


.active-animation {
  position: absolute;
  background-image: linear-gradient(90deg, red 50%, transparent 50%), linear-gradient(90deg, red 50%, transparent 50%), linear-gradient(0deg, red 50%, transparent 50%), linear-gradient(0deg, red 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 6px 3px, 6px 3px, 3px 6px, 3px 6px;
  //background-size: 4px 2px, 4px 2px, 2px 4px, 2px 4px;
  background-position: left top, right bottom, left bottom, right top;
  /*animation: border-dance 1.4s infinite linear;*/
  //background-color: #ff000042;
}

.changeBoxNew {
  border: 1px solid blue;
  background-image: linear-gradient(90deg, blue 50%, transparent 50%), linear-gradient(90deg, blue 50%, transparent 50%), linear-gradient(0deg, blue 50%, transparent 50%), linear-gradient(0deg, blue 50%, transparent 50%);
  background-color: rgba(99, 178, 255, 0.26);
}

.changeBoxText {
  border: 1px solid orange;
  background-image: linear-gradient(90deg, orange 50%, transparent 50%), linear-gradient(90deg, orange 50%, transparent 50%), linear-gradient(0deg, orange 50%, transparent 50%), linear-gradient(0deg, orange 50%, transparent 50%);
}

.active-animation-small {
  background-image: linear-gradient(90deg, red 50%, transparent 50%), linear-gradient(90deg, red 50%, transparent 50%), linear-gradient(0deg, red 50%, transparent 50%), linear-gradient(0deg, red 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 3px, 15px 3px, 3px 15px, 3px 15px;
  background-position: left top, right bottom, left bottom, right top;
  /*animation: border-dance 1s infinite linear;*/
}

.animation-active {
  animation: border-dance 1s infinite linear;
}

.animation-active-high {
  animation: border-dance 1s infinite linear;
  background-image: linear-gradient(90deg, red 50%, transparent 50%), linear-gradient(90deg, red 50%, transparent 50%), linear-gradient(0deg, red 50%, transparent 50%), linear-gradient(0deg, red 50%, transparent 50%);
}
.animation-active-medium {
  animation: border-dance 1s infinite linear;
  background-image: linear-gradient(90deg, orange 50%, transparent 50%), linear-gradient(90deg, orange 50%, transparent 50%), linear-gradient(0deg, orange 50%, transparent 50%), linear-gradient(0deg, orange 50%, transparent 50%);
}
.animation-active-low {
  animation: border-dance 1.5s infinite linear;
  background-image: linear-gradient(90deg, orange 50%, transparent 50%), linear-gradient(90deg, orange 50%, transparent 50%), linear-gradient(0deg, orange 50%, transparent 50%), linear-gradient(0deg, orange 50%, transparent 50%);
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 18px top, right 18px bottom, left bottom 18px, right top 18px;
  }
}

.diff_neutral {
  color: gray;
}

.diff_removal {
  color: red;
}

.diff_insert {
  color: green;
}

</style>