import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client/core"
import { getMainDefinition } from "@apollo/client/utilities"
import { onError } from "@apollo/client/link/error"
import { logErrorMessages } from "@vue/apollo-util"

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

function getHeaders() {
    const headers = {}
    let token = window.localStorage.getItem("pagewatch-token")
    if (!document.location.pathname.startsWith("/r/") && document.location.pathname.split('/')[1].length === 36) {
        token = document.location.pathname.split('/')[1]
    }
    if (token) {
        headers["Authorization"] = `Token ${token}`
    }
    return headers
}

const wsLink = new GraphQLWsLink(
    createClient({
        // lazy: true,
        url: import.meta.env.VITE_HASURA_WSLINK,
        connectionParams: () => {
            return {headers: getHeaders()}
        }
    }),
);

// Create an http link:
const httpLink = new HttpLink({
    uri: import.meta.env.VITE_HASURA_HTTPLINK,
    fetch: (uri, options) => {
        options.headers = getHeaders()
        return fetch(uri, options)
    },
})

const errorLink = onError((error) => {
    // if (import.meta.env.NODE_ENV !== "production") {
    logErrorMessages(error)
    // }
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({query}) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        )
    },
    errorLink.concat(wsLink),
    errorLink.concat(httpLink)
)

// Create the apollo client
export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: link,
})