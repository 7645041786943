import Preview from "@/views/preview/Preview.vue";
import PreviewOverview from "@/views/preview/Overview.vue";

export function previewRoutes(parentName) {
    return {
        path: "preview/:pageId/:deviceId",
        component:
        Preview,
        children:
            [
                {
                    path: "",
                    name: `preview-overview-${parentName}`,
                    component: PreviewOverview,
                },
            ],
        beforeEnter:
            (to, from) => {
                to.meta.parentName = parentName;
                to.meta.origin = from.fullPath;
            },
    }
}