export default {
    'chrome_1366': {
        "id": "chrome_1366",
        "os": "desktop",
        "name": "1366x768",
        "platform": "desktop",
        "userAgent": "",
        "viewport": {
            "width": 1366,
            "height": 768,
            "screenHeight": 768,
            "deviceScaleFactor": 1,
            "isMobile": true,
            "hasTouch": true,
            "isLandscape": false,
        },
        "preview": {
            "mockup":{
               "height": 1000,
                "width": 1600,
            },
            "scrollbars":{
                "visible": true,
            },
            "screenshot": {
                "marginTop": 78,
                "marginLeft": 0,
                "height": 890,
                "width": 1588,
            },
            "header": {
                "visible": false,
                "top": 0,
                "left": 0,
                "width": 0,
                "height": 0,
            },
            "footer": {
                "visible": false,
                "top": 0,
                "left": 0,
                "width": 0,
                "height": 0,
            }

        }
    },
    'chrome_1280': {
        "id": "chrome_1280",
        "os": "desktop",
        "name": "Desktop",
        "platform": "desktop",
        "userAgent": "",
        "viewport": {
            "width": 1280,
            "height": 1024,
            "screenHeight": 1024,
            "deviceScaleFactor": 1,
            "isMobile": true,
            "hasTouch": true,
            "isLandscape": false,
        },
        "preview": {
            "mockup":{
               "height": 1000*1600/1280+160,
                "width": 1280*1600/1280,
            },
            "scrollbars":{
                "visible": true,
            },
            "screenshot": {
                "marginTop": 102,
                "marginLeft": 0,
                "height": 1280,
                "width": 1576,
            },
            "header": {
                "visible": false,
                "top": 0,
                "left": 0,
                "width": 0,
                "height": 0,
            },
            "footer": {
                "visible": false,
                "top": 0,
                "left": 0,
                "width": 0,
                "height": 0,
            }

        }
    },
    'galaxy_s21': {
        "id": "galaxy_s21",
        "os": "android",
        "name": "Galaxy S21",
        "platform": "android",
        "userAgent": "",
        "viewport": {
            "width": 360,
            "height": 670,
            "screenHeight": 800,
            "deviceScaleFactor": 3,
            "isMobile": true,
            "hasTouch": true,
            "isLandscape": false,
        },
        "preview": {
            "scale": 0.6,
            "scrollbars":{
                "visible": false
            },
            "screenshot": {
                "top": 37,
                "left": 5,
                "width": 138,
                "height": 256,
            },
            "header": {
                "visible": true,
                "top": 5,
                "left": 5,
                "width": 138,
                "height": 0,
            },
            "footer": {
                "visible": true,
                "top": 293,
                "left": 5,
                "width": 138,
                "height": 0,
            }

        }
    },
    'iphone_14': {
        "id": "iphone_14",
        "os": "ios",
        "name": "iPhone 14",
        "platform": "ios",
        "userAgent": "",
        "viewport": {
            "width": 360,
            "height": 670,
            "screenHeight": 800,
            "deviceScaleFactor": 3,
            "isMobile": true,
            "hasTouch": true,
            "isLandscape": false,
        },
        "preview": {
            "mockup":{
               "height": 5310,
                "width": 2624,
            },
            "scrollbars":{
                "visible": false
            },
            "screenshot": {
                "marginTop": 324,
                "marginLeft": 144,
                "height": 3964,
                "width": 2352,
            },
            "header": {
                "visible": true,
                "top": 140,
                "left": 1962,
                "width": 300,
                "height": 0,
            },
            "footer": {
                "visible": true,
                "top": 4282,
                "left": 26,
                "width": 2600,
                "height": 0,
            }

        }
    },
    'ipad_pro_11': {
        "id": "ipad_pro_11",
        "os": "tablet",
        "name": "iPad Pro 11",
        "platform": "tablet",
        "userAgent": "",
        "viewport": {
            "width": 360,
            "height": 670,
            "screenHeight": 800,
            "deviceScaleFactor": 3,
            "isMobile": true,
            "hasTouch": true,
            "isLandscape": false,
        },
        "preview": {
            "scrollbars":{
                "visible": false
            },
            "mockup":{
               "height": 2930,
                "width": 2245,
            },
            "screenshot": {
                "marginTop": 274,
                "marginLeft": 98,
                "height": 2560,
                "width": 2052,
            },
            "header": {
                "visible": true,
                "top": 100,
                "left": 100,
                "width": 2042,
                "height": 100,
            },
            "footer": {
                "visible": false,
                "top": 0,
                "left": 0,
                "width": 0,
                "height": 0,
            }

        }
    }
}