<script setup>
import { computed, onMounted } from "vue";
import { useTemplateStore } from "@/stores/template";

// Import all layout partials
import BaseHeader from "@/layouts/partials/Header.vue";
import BaseSidebar from "@/layouts/partials/Sidebar.vue";
import BaseFooter from "@/layouts/partials/Footer.vue";

// Component properties
defineProps({
});

// Main store
const store = useTemplateStore();

// Render main classes based on store options
const classContainer = computed(() => {
  return {
    "sidebar-r": false,
    "sidebar-o": store.layout.sidebar && store.settings.sidebarVisibleDesktop,
    "sidebar-o-xs": store.layout.sidebar && store.settings.sidebarVisibleMobile,
    "sidebar-dark": true,
    "side-overlay-o":
      store.layout.sideOverlay && store.settings.sideOverlayVisible,
    "side-overlay-hover":
      store.layout.sideOverlay && store.settings.sideOverlayHoverable,
    "page-header-fixed": store.layout.header && store.settings.headerFixed,
    "page-header-dark": false,
    "main-content-boxed": store.settings.mainContent === "boxed",
    "main-content-narrow": store.settings.mainContent === "narrow",
    "rtl-support": store.settings.rtlSupport,
    "side-trans-enabled": store.settings.sideTransitions,
    "side-scroll": true,
  };
});


// Remove side transitions on window resizing
onMounted(() => {
  let winResize = false;

  window.addEventListener("resize", () => {
    clearTimeout(winResize);

    store.setSideTransitions({ transitions: false });

    winResize = setTimeout(() => {
      store.setSideTransitions({ transitions: true });
    }, 500);
  });
});
</script>

<template>
  <div id="page-container" :class="classContainer">

    <!-- Sidebar -->
    <BaseSidebar
      v-if="store.layout.sidebar"
    >
      <template #header>
        <slot name="sidebar-header"></slot>
      </template>

      <template #header-extra>
        <slot name="sidebar-header-extra"></slot>
      </template>

      <template #content>
        <slot name="sidebar-content"></slot>
      </template>

      <slot name="sidebar"></slot>
    </BaseSidebar>
    <!-- END Sidebar -->

    <!-- Header -->
    <BaseHeader v-if="store.layout.header">
      <template #content-left>
        <slot name="header-content-left"></slot>
      </template>

      <template #content-right>
        <slot name="header-content-right"></slot>
      </template>

      <template #content>
        <slot name="header-content"></slot>
      </template>
      <slot name="header"></slot>
    </BaseHeader>
    <!-- END Header -->

    <!-- Main Container -->
    <div id="main-container">
      <slot name="page-top-content"></slot>
      <RouterView />
    </div>
    <!-- END Main Container -->
  </div>
</template>

<style>
#main-container{
  overflow: auto;
  max-height:calc( 100vh - 4rem);
}
</style>