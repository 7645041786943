<script setup>
import { useGrid, useScreen } from 'vue-screen'
import { useRoute, useRouter } from "vue-router";
import DevicePreview from "@/components/DevicePreview.vue";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useWebsiteStore } from "@/stores/website";
import AiSection from "@/views/preview/sections/AiSection.vue";
import PerformanceSection from "@/views/preview/sections/PerformanceSection.vue";
import IssueSection from "@/views/preview/sections/IssueSection.vue";
import linkifyHtml from "linkify-html";
import RadialProgress from "vue3-radial-progress";
import HistorySection from "@/views/preview/sections/HistorySection.vue";
import HighlightBox from "@/views/preview/partials/HighlightBox.vue";
import HttpStatusCodeBadge from "@/components/HttpStatusCodeBadge.vue";
import PerformanceIssue from "@/views/preview/partials/PerformanceIssue.vue";

const websiteStore = useWebsiteStore();
const activeDeviceRender = useActiveDeviceRenderStore();
const router = useRouter()
const route = useRoute()
const screen = useScreen()
const grid = useGrid('bootstrap5')
const {scanStatus, lastUpdated, website} = storeToRefs(websiteStore);


function truncate(value, length = 36) {
  if (value.length >= length) {
    return value.substring(0, length) + '...'
  }
  return value

}

// --------------- Issue Items --------------- //

function processOccurrences(issueType, callbackFunction = null) {
  let result = []
  for (let deviceRender of activeDeviceRender.deviceRenders) {
    if (deviceRender[issueType]) {
      for (let entry of deviceRender[issueType]) {
        let existing = result.find((item) => {
          return item.id === entry.id
        });

        if (existing) {
          if (!existing['devices'].map(d => d.name).includes(deviceRender.name)) {
            existing['devices'].push({
              name: deviceRender.name,
              renderId: deviceRender.id,
              occurrences: entry.occurrences,
              id: deviceRender.deviceId,
            })
          }
          if (deviceRender.deviceId === activeDeviceRender.deviceRender.deviceId) {
            existing.occurrences = JSON.parse(JSON.stringify(entry.occurrences));
            existing.priority = 'high';
            if (callbackFunction) {
              callbackFunction(entry, existing);
            }
          }
        } else {
          let box = {}
          Object.assign(box, entry.occurrences[0].box);
          let newItem = {
            id: entry.id,
            reason: entry.reason,
            occurrences: deviceRender.deviceId === activeDeviceRender.deviceRender.deviceId ? entry.occurrences : [],
            devices: [{
              name: deviceRender.name,
              renderId: deviceRender.id,
              occurrences: entry.occurrences,
              id: deviceRender.deviceId,
            }]
          }
          newItem = JSON.parse(JSON.stringify(newItem))

          if (callbackFunction) {
            callbackFunction(entry, newItem);
          }

          result.push(newItem)
        }
      }
    }
  }
  return result
}

const crawlErrors = computed(() => {
  let result = [];
  for (let deviceRender of activeDeviceRender.deviceRenders) {
    if (deviceRender.crawlErrors) {
      result = result.concat(deviceRender.crawlErrors);
    }
  }
  return result;
})

const cloudFlareError = computed(() => {

  return !!crawlErrors.value.find((error) => {
    return error.reason === 'cloudflare'
  });


})

const spellingMarks = computed(() => {

  let entries = processOccurrences('spellingMarks', function (entry, newItem) {
    newItem.word = entry.word;
    newItem.suggestion = entry.suggestion;
    newItem.query = 'spelling';
    newItem.priority = entry.priority || 'high';
    newItem.ignoreButton = {
      show: true,
    };

    newItem.name = `${entry.word} --> ${entry.suggestion}`;
    newItem.description = ''

    for (let occurrence of newItem.occurrences) {
      occurrence.highlightDetails = {}

      if (entry.word.toLowerCase() === entry.suggestion.toLowerCase()) {
        occurrence.highlightDetails.title = `Inconsistent Capitalization: ${entry.word}`;
        occurrence.highlightDetails.text_1 = `Suggestion: ${entry.suggestion}`;
        occurrence.highlightDetails.context = occurrence.context;
        occurrence.highlightDetails.showContext = !occurrence.visible || (['title', 'alt'].includes(occurrence.context_type));
      } else {
        if (['title', 'alt'].includes(occurrence.context_type)) {
          occurrence.highlightDetails.title = `Possible spelling error in ${occurrence.context_type} text: ${entry.word}`;
        } else {
          occurrence.highlightDetails.title = `Possible spelling error: ${entry.word}`;
        }
        occurrence.highlightDetails.text_1 = `Suggestion: ${entry.suggestion}`;
        occurrence.highlightDetails.context = occurrence.context;
        occurrence.highlightDetails.showContext = !occurrence.visible || (['title', 'alt'].includes(occurrence.context_type));
      }

      if (occurrence.box && occurrence.box.height > 500) {
        occurrence.box.height = 50;
      }

    }

    newItem.occurrences.sort((a, b) => {
      if (a.visible && !b.visible) return -1;
      if (!a.visible && b.visible) return 1;
      if (a.box.top < b.box.top) return -1;
      if (a.box.top > b.box.top) return 1;
      if (a.box.left < b.box.left) return -1;
      if (a.box.left > b.box.left) return 1;
      return 0;
    });

  });
  entries.sort((a, b) => {
    if (!a.occurrences[0] || !b.occurrences[0]) {
      return 0;
    }
    // First, sort by 'visible' (true values first)
    let occurrencesA = a.occurrences[0];
    let occurrencesB = b.occurrences[0];

    if (occurrencesA.visible && !occurrencesB.visible) return -1;
    if (!occurrencesA.visible && occurrencesB.visible) return 1;

    if (occurrencesA.box.top < occurrencesB.box.top) return -1;
    if (occurrencesA.box.top > occurrencesB.box.top) return 1;

    if (occurrencesA.box.left < occurrencesB.box.left) return -1;
    if (occurrencesA.box.left > occurrencesB.box.left) return 1;

    return 0; // If all criteria are equal
  });
  return entries;

})

const inconsistentCapitalizationMarks = computed(() => {

  return processOccurrences('inconsistentCapitalizationMarks', function (entry, newItem) {
    newItem.word = entry.word;
    newItem.suggestion = entry.suggestion;
    newItem.query = 'inconsistent_capitalization';
    newItem.priority = entry.priority || 'low';

    newItem.name = `${entry.word} --> ${entry.suggestion}`;
    newItem.description = ''
    newItem.ignoreButton = {
      show: true,
    };

    for (let occurrence of newItem.occurrences) {
      occurrence.highlightDetails = {}
      occurrence.highlightDetails.title = `Inconsistent Capitalization: ${entry.word}`;
      occurrence.highlightDetails.text_1 = `Suggestion: ${entry.suggestion}`;
      occurrence.highlightDetails.context = occurrence.context;
      occurrence.highlightDetails.showContext = !occurrence.visible || (['title', 'alt'].includes(occurrence.contextType));

      if (occurrence.box && occurrence.box.height > 500) {
        occurrence.box.height = 50;
      }
    }

  });

})

const contentIssueMarks = computed(() => {

  return processOccurrences('contentIssueMarks', function (entry, newItem) {
    newItem.reason = entry.reason;
    newItem.query = 'content';
    newItem.priority = entry.priority || 'low';

    newItem.name = `${entry.reason}`;
    newItem.description = ''

    if (entry.reason === 'copyright') {
      newItem.name = `Outdated Copyright Notice (${entry.expire_year})`;
    }

    for (let occurrence of newItem.occurrences) {
      occurrence.highlightDetails = {}
      occurrence.highlightDetails.title = `Outdated Copyright Notice (${entry.expire_year})`;
      occurrence.highlightDetails.text_1 = `The copyright notice should be updated to the current year`;

      if (occurrence.box && occurrence.box.height > 500) {
        occurrence.box.height = 50;
      }
    }

  });

})

const seoErrors = computed(() => {

  let result = []

  if (activeDeviceRender.page.seoIssueMissingTitle) {
    result.push({
      id: 'seo_issue_missing_title',
      name: 'Missing Document Title',
      description: 'Page titles are crucial for usability and SEO.',
      occurrences: [],
      devices: []
    })
  }

  return result

})

const contentErrors = computed(() => {

  let result = []
  if (activeDeviceRender.page.contentIssueMissingFavicon) {
    result.push({
      id: 'content_issue_missing_favicon',
      name: 'Missing Favicon',
      description: 'Favicon images are important for branding and usability.',
      occurrences: [],
      devices: []
    })
  }

  return result

})

const seoWarnings = computed(() => {

  let result = []

  if (activeDeviceRender.page.seoIssueMissingDescription) {
    result.push({
      id: 'seo_issue_missing_description',
      name: 'Missing Meta Description',
      description: 'Meta descriptions are important for usability and SEO.',
      occurrences: [],
      devices: []
    })
  }


  return result

})


const layoutErrors = computed(() => {

  return processOccurrences('layoutErrors', function (entry, newItem) {

    newItem.query = 'layout';
    newItem.priority = entry.priority || 'high';

    let errorName = entry.reason;
    let reason = entry.reason;
    let description = '';
    if (reason === 'horizontal_scrolling') {
      errorName = 'Horizontal Scrolling Detected'
      description = 'Unless intended pages should not scroll in two dimensions. '
    }
    if (reason === 'text_overlap') {
      errorName = 'Overlapping Text Detected'
      description = 'Overlapping text can cause severe readability issues.'
    }
    if (reason === 'entity') {
      errorName = 'Character Escaping Error'
      description = `"${entry.occurrences[0].word}" was double escaped, did you mean "${entry.occurrences[0].suggestion}".`
    }
    if (['overflow_w', 'overflow_h'].includes(reason)) {
      errorName = 'Overflowing Container'
      description = `"${entry.occurrences[0].text}" is overflowing its container.`
    }
    if (reason === 'lsep') {
      errorName = 'LSEP character (invisible line break) detected'
      description = 'On certain browsers this will display as a box (☐) or cause spacing issues between words.'
      newItem.priority = 'medium';
    }
    if (reason === 'etx') {
      errorName = 'ETX character ("End of Text") detected'
      description = 'On certain browsers this will display as a box (☐) or cause spacing issues between words.'
      newItem.priority = 'medium';
    }
    if (reason === 'aspect_ratio') {
      errorName = 'Incorrect Aspect Ratio'
      description = 'Image displayed with incorrect aspect ratio.'
    }
    if (reason === 'unsupported_unicode') {
      errorName = 'Unsupported Unicode Character'
      description = 'These might display as a box (☐) or similar depending on the browser.'
    }
    if (reason === 'narrow') {
      errorName = 'Narrow Vertical Text'
      description = 'Very tall and narrow text usually is caused by a missing breakpoint.'
    }
    if (reason === 'outside_window') {
      errorName = 'Text flows outside of window'
      description = entry.occurrences[0].text

    }
    if (reason === 'button_overlap') {
      errorName = 'Overlapping Buttons Detected'
      description = `The button "${truncate(entry.occurrences[0].text, 35)}" overlaps with another button`
    }
    newItem.name = errorName;
    newItem.description = description;
    for (let occurrence of newItem.occurrences) {
      occurrence.highlightDetails = {}

      occurrence.highlightDetails.title = errorName;
      occurrence.highlightDetails.text_1 = description;
      if (reason === 'horizontal_scrolling') {
        occurrence.highlightDetails.text_2 = 'The element below is the most likely cause.'
      }
      if (reason === 'aspect_ratio') {
        occurrence.highlightDetails.text_2 = `<a title="External Link" target="_blank" href="${occurrence.url}" class="external-link overflow-text-ellipsis-inline">${occurrence.url}</a>`;
      }
      occurrence.highlightDetails.showContext = false;


      if (['outside_window', 'unsupported_unicode'].includes(reason)) {
        occurrence.box.width += 4;
        occurrence.box.height += 4;
        occurrence.box.left -= 2;
        occurrence.box.top -= 2;
      }
      if (['button_overlap', 'text_overlap'].includes(reason)) {
        occurrence.box.top = Math.min(occurrence.box.top, occurrence.box2.top)
        occurrence.box.left = Math.min(occurrence.box.left, occurrence.box2.left)
        occurrence.box.height = Math.max(occurrence.box.top + occurrence.box.height, occurrence.box2.top + occurrence.box2.height) - occurrence.box.top
        occurrence.box.width = Math.max(occurrence.box.left + occurrence.box.width, occurrence.box2.left + occurrence.box2.width) - occurrence.box.left
      }
      if ((['horizontal_scrolling'].includes(reason) || occurrence.highlightDetails.showContext) && occurrence.box.height > 500) {
        occurrence.box.height = 50;
      }

    }

  });

})

const consoleErrors = computed(() => {
  let results = []
  for (let consoleLog of activeDeviceRender.page.consoleLogIssues) {
    if (!['error', 'pageerror'].includes(consoleLog.type)) {
      continue;
    }
    results.push({
      text: consoleLog.stack || consoleLog.text,
      occurrences: consoleLog.occurrences,
      type: consoleLog.type,
    })
  }
  return results;
})

const consoleWarnings = computed(() => {
  let results = []
  for (let consoleLog of activeDeviceRender.page.consoleLogIssues) {
    if (!['warning'].includes(consoleLog.type)) {
      continue;
    }
    results.push({
      text: consoleLog.stack || consoleLog.text,
      occurrences: consoleLog.occurrences,
      type: consoleLog.type,
    })
  }
  return results;
})

const brokenResources = computed(() => {
  let results = []
  for (let brokenResource of activeDeviceRender.page.brokenResourceIssues) {
    results.push({
      url: brokenResource.url,
      status: brokenResource.status,
      type: brokenResource.type,
    })
  }
  return results;
})

const brokenLinkMarks = computed(() => {

  let entries = processOccurrences('brokenLinkMarks', function (entry, newItem) {
    newItem.query = 'broken_link';

    newItem.priority = entry.priority || 'high';
    newItem.showStatusCode = true;
    newItem.statusCode = `${entry.status}`;
    newItem.name = `${entry.occurrences[0].text}`;
    newItem.description = entry.url;

    for (let occurrence of newItem.occurrences) {
      occurrence.highlightDetails = {}

      occurrence.highlightDetails.title = `Broken Link Detected`;
      occurrence.highlightDetails.text_1 = linkifyHtml(entry.url, {
        defaultProtocol: 'https',
        className: 'external-link-plain',
        target: {
          url: '_blank'
        }
      });
      occurrence.highlightDetails.context = occurrence.context;
      occurrence.highlightDetails.showContext = !occurrence.visible || (['title', 'alt'].includes(occurrence.contextType));

      if (occurrence.box && occurrence.box.height > 500) {
        occurrence.box.height = 50;
      }

    }

  });
  entries.sort((a, b) => {
    if (!a.occurrences[0] || !b.occurrences[0]) {
      return 0;
    }
    // First, sort by 'visible' (true values first)
    let occurrencesA = a.occurrences[0];
    let occurrencesB = b.occurrences[0];

    if (occurrencesA.visible && !occurrencesB.visible) return -1;
    if (!occurrencesA.visible && occurrencesB.visible) return 1;

    if (occurrencesA.box.top < occurrencesB.box.top) return -1;
    if (occurrencesA.box.top > occurrencesB.box.top) return 1;

    if (occurrencesA.box.left < occurrencesB.box.left) return -1;
    if (occurrencesA.box.left > occurrencesB.box.left) return 1;

    return 0; // If all criteria are equal
  });
  return entries

})

const internalRedirectMarks = computed(() => {

  let entries = processOccurrences('internalRedirectMarks', function (entry, newItem) {
    newItem.query = 'internal_redirect';
    newItem.priority = entry.priority || 'low';

    newItem.name = `${entry.url}`;
    newItem.description = `Redirected to: ${entry.redirected_to}`;

    for (let occurrence of newItem.occurrences) {
      occurrence.highlightDetails = {}

      occurrence.highlightDetails.title = `Internal Redirect Detected`;
      occurrence.highlightDetails.text_1 = linkifyHtml(entry.url, {
        defaultProtocol: 'https',
        className: 'external-link',
        target: {
          url: '_blank'
        }
      });
      occurrence.highlightDetails.text_2 = 'Redirected to: ' + linkifyHtml(entry.redirected_to, {
        defaultProtocol: 'https',
        className: 'external-link',
        target: {
          url: '_blank'
        }
      });
      occurrence.highlightDetails.context = occurrence.context;
      occurrence.highlightDetails.showContext = !occurrence.visible || (['title', 'alt'].includes(occurrence.contextType));

      if (occurrence.box && occurrence.box.height > 500) {
        occurrence.box.height = 50;
      }

    }


  });

  entries.sort((a, b) => {
    if (!a.occurrences[0] || !b.occurrences[0]) {
      return 0;
    }
    // First, sort by 'visible' (true values first)
    let occurrencesA = a.occurrences[0];
    let occurrencesB = b.occurrences[0];

    if (!(occurrencesA && occurrencesB)) {
      return 0;
    }

    if (occurrencesA.visible && !occurrencesB.visible) return -1;
    if (!occurrencesA.visible && occurrencesB.visible) return 1;

    if (occurrencesA.box.top < occurrencesB.box.top) return -1;
    if (occurrencesA.box.top > occurrencesB.box.top) return 1;

    if (occurrencesA.box.left < occurrencesB.box.left) return -1;
    if (occurrencesA.box.left > occurrencesB.box.left) return 1;

    return 0; // If all criteria are equal
  });
  return entries

})

const brokenImageMarks = computed(() => {

  let entries = processOccurrences('brokenImageMarks', function (entry, newItem) {
    newItem.query = 'broken_image';

    newItem.priority = entry.priority || 'high';
    newItem.name = '';
    newItem.description = entry.url;

    for (let occurrence of newItem.occurrences) {
      occurrence.highlightDetails = {}

      occurrence.highlightDetails.title = `Broken Image Detected`;
      occurrence.highlightDetails.text_1 = linkifyHtml(entry.url, {
        defaultProtocol: 'https',
        className: 'external-link',
        target: {
          url: '_blank'
        }
      });
      occurrence.highlightDetails.context = occurrence.context;
      occurrence.highlightDetails.showContext = false;

      if (occurrence.box && occurrence.box.height > 500) {
        occurrence.box.height = 50;
      }

    }

  });
  entries.sort((a, b) => {
    if (!a.occurrences[0] || !b.occurrences[0]) {
      return 0;
    }
    // First, sort by 'visible' (true values first)
    let occurrencesA = a.occurrences[0];
    let occurrencesB = b.occurrences[0];

    if (!occurrencesA) {
      return -1;
    }
    if (!occurrencesB) {
      return 1;
    }

    if (occurrencesA.visible && !occurrencesB.visible) return -1;
    if (!occurrencesA.visible && occurrencesB.visible) return 1;

    if (occurrencesA.box.top < occurrencesB.box.top) return -1;
    if (occurrencesA.box.top > occurrencesB.box.top) return 1;

    if (occurrencesA.box.left < occurrencesB.box.left) return -1;
    if (occurrencesA.box.left > occurrencesB.box.left) return 1;

    return 0; // If all criteria are equal
  });
  return entries

})

const seoIssues2 = computed(() => {
  let result = []
  if (activeDeviceRender.page.seoIssueMissingTitle) {
    result.push({
      id: 'seo_issue_missing_title',
      name: 'Missing Document Title',
      description: 'Pages should have a title.',
      occurrences: 1,
    })
  }
  if (activeDeviceRender.page.seoIssueMissingDescription) {
    result.push({
      id: 'seo_issue_missing_description',
      name: 'Missing Meta Description',
      description: 'Pages should have a description.',
      occurrences: 1,
    })
  }
  return result;
})

// --------------- /Issue Items --------------- //

function replaceLink(deviceId) {
  let params = {...route.params};
  let query = {...route.query};
  params.deviceId = deviceId;
  // Go directly to audit instead of ai, hack to avoid reloading
  if (query.section === 'ai'){
    query = {}
  }
  router.push({name: route.name, params: params, query: query});
}

const devicePreview = ref(null);
const highlightBox = ref(null);
const highlightDetails = ref({
  content: {
    title: '',
    text_1: '',
    text_2: '',
    url: '',
  },
  style: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    'z-index': '1000',
    visibility: 'hidden',
  },
  index: 0,
  box: 0,
  nextLink: {
    query: {},
    show: false,
    style: {
      color: 'white',
      opacity: 0.8
    }
  },
  prevLink: {
    query: {},
    show: false,
    style: {
      color: 'white',
      opacity: 0.8
    }
  }
})

function hideHighlightDetails() {
  highlightDetails.value.content = {
    title: '',
    text_1: '',
    text_2: '',
    context: '',
    showContext: false,
  }
  highlightDetails.value.style.visibility = 'hidden'
}

let performanceScore = computed(() => {
  if (activeDeviceRender.page.lighthouse && activeDeviceRender.page.lighthouse.performance_score) {
    let p = activeDeviceRender.page.lighthouse.performance_score;
    // round to integer
    return Math.round(p * 100);
  }
  return ''

})

function showBoxes() {
  if (route.query.section) {
    return
  }
  if (!devicePreview.value) {
    return;
  }

  let issues = brokenLinkMarks.value
      .concat(internalRedirectMarks.value)
      .concat(brokenImageMarks.value)
      .concat(layoutErrors.value)
      .concat(spellingMarks.value)
      .concat(contentIssueMarks.value)
      .concat(inconsistentCapitalizationMarks.value);

  let highlightBoxes = []
  let oldHighlightBoxes = []
  let query = {}
  let ratio = devicePreview.value.ratio();

  let activeIssue = getIssueFromQuery(route.query).entry;

  let count = 0;
  for (let issue of issues) {

    let index = 0;
    for (let occurrence of issue.occurrences) {
      if (!occurrence) {
        highlightBoxes = []
        return
      }
      count += 1;
      index += 1;

      let box = {}
      Object.assign(box, occurrence.box);
      let q = {}
      Object.assign(q, query);
      q['i'] = index;
      q[issue.query] = issue.id;

      if (occurrence.id === 'text_overlap') {
        box.left = Math.min(occurrence.box.left, occurrence.box2.left);
        box.top = Math.min(occurrence.box.top, occurrence.box2.top);
        box.width = Math.max(occurrence.box.left + occurrence.box.width, occurrence.box2.left + occurrence.box2.width) - box.left;
        box.height = Math.max(occurrence.box.top + occurrence.box.height, occurrence.box2.top + occurrence.box2.height) - box.top;
      }
      if (box.width < 12) {
        box.left -= 6;
        box.top -= 6;
        box.width += 12;
        box.height += 12;
      }

      let variant = {
        'type': 'box',
        'color': 'red',
        'sentiment': 'negative',
      }
      if (occurrence.variant) {
        variant = occurrence.variant;
      }

      let id = `highlight-box-inactive-${count}`
      if (activeIssue && activeIssue.id === issue.id) {
        id = `highlight-box-${index}`
      }

      highlightBoxes.push({
        'id': id,
        'key': id,
        'i': `${index}`,
        'highlight': false,
        'priority': issue.priority,
        'active': activeIssue && activeIssue.id === issue.id,
        'sentiment': variant.sentiment,
        'box': box,
        'style': {
          'visibility': 'visible',
          'left': `${box.left * ratio}px`,
          'top': `${box.top * ratio}px`,
          'width': `${box.width * ratio}px`,
          'height': `${box.height * ratio}px`,
        },
        'query': q,
        'variant': variant
      })
    }
  }
  devicePreview.value.showBoxes(highlightBoxes)

}

function highlightEntry(entry) {
  let index = parseInt(route.query.i, 10) || 1;

  let occurrences = JSON.parse(JSON.stringify(entry.occurrences))
  let occurrence = entry.occurrences[index - 1];

  if (!occurrence) {
    return
  }

  let query = {}
  query[entry.query] = entry.id;
  occurrences.sort((a, b) => {
    if (a.box.top > b.box.top) {
      return 1
    }
    if (a.box.top < b.box.top) {
      return -1
    }
    return 0
  })
  highlightDetails.value.nextLink.query = {};
  highlightDetails.value.prevLink.query = {};
  Object.assign(highlightDetails.value.nextLink.query, query);
  Object.assign(highlightDetails.value.prevLink.query, query);
  highlightDetails.value.nextLink.query.i = index + 1
  highlightDetails.value.prevLink.query.i = index - 1
  highlightDetails.value.nextLink.show = true
  highlightDetails.value.prevLink.show = true
  if (index === 1) {
    highlightDetails.value.prevLink.show = false
  }
  if (index === occurrences.length) {
    highlightDetails.value.nextLink.show = false
  }

  highlightDetails.value.content = occurrence.highlightDetails;
  showBoxes()
  devicePreview.value.scrollToBox(occurrence.box)
}


function getIssueFromQuery(query) {

  let index = parseInt(query.i, 10) || 1;
  let result = {
    section: query.section,
    index: index - 1,
    entry: null,
  }
  if (!devicePreview.value) {
    return result;
  }

  if (query.section === 'ai') {
    if (index > 0) {
      return result;
    }
    return {};
  }

  if (query.spelling) {
    let spellingMark = spellingMarks.value.find((spellingMark) => {
      return spellingMark.id === query.spelling;
    })
    if (spellingMark) {
      result.entry = spellingMark;
      return result;
    }
  } else if (query.inconsistent_capitalization) {
    let spellingMark = inconsistentCapitalizationMarks.value.find((spellingMark) => {
      return spellingMark.id === query.inconsistent_capitalization;
    })
    if (spellingMark) {
      result.entry = spellingMark;
      return result;
    }
  } else if (query.layout) {
    let layoutError = layoutErrors.value.find((layoutError) => {
      return layoutError.id === query.layout;
    })
    if (layoutError) {
      result.entry = layoutError;
      return result;
    }
  } else if (query.broken_link) {
    let brokenLink = brokenLinkMarks.value.find((brokenLink) => {
      return brokenLink.id === query.broken_link;
    })
    if (brokenLink) {
      result.entry = brokenLink;
      return result;
    }
  } else if (query.content) {
    let contentIssue = contentIssueMarks.value.find((contentIssue) => {
      return contentIssue.id === query.content;
    })
    if (contentIssue) {
      result.entry = contentIssue;
      return result;
    }
  } else if (query.internal_redirect) {
    let internalRedirect = internalRedirectMarks.value.find((internalRedirect) => {
      return internalRedirect.id === query.internal_redirect;
    })
    if (internalRedirect) {
      result.entry = internalRedirect;
      return result;
    }
  } else if (query.broken_image) {
    let brokenImage = brokenImageMarks.value.find((brokenImage) => {
      return brokenImage.id === query.broken_image;
    })

    if (brokenImage) {
      result.entry = brokenImage;
      return result;
    }
  } else if (query.history) {
    return result;
  } else {
    return result;
  }

  return {}
}

function scrollToIssue() {

  hideHighlightDetails();
  // devicePreview.value.highlightEntries();
  let issue = getIssueFromQuery(route.query);
  if (issue.entry) {
    highlightEntry(issue.entry)
  } else {
    hideHighlightDetails();
    // devicePreview.value.highlightEntries();
  }
}

watch(() => route.query, (query) => {
  showBoxes()
  scrollToIssue()
})

function onLoaded(options) {
  showBoxes()
  scrollToIssue()
}

function scroll(dir) {
  hideHighlightDetails();
  devicePreview.value.scroll(dir)
}

function onManualScroll(scrollPosition) {
  hideHighlightDetails();
}

function onScrollToEntryCompleted() {
  let query = route.query;
  let index = parseInt(query.i, 10) || 0;
  if (highlightDetails.value.content.title) {
    highlightBox.value.showHighlightDetails()
  }
}

const maxWidth = computed(() => {
  if (grid.xl) {
    return 600;
  }
  if (grid.lg) {
    return 368;
  }
  return Math.min(screen.width - 100, 350)
})

const historyPreview = computed(() => {
  if (activeDeviceRender.historyData) {

    return {
      show: true,
      screenshotsNew: activeDeviceRender.historyData.screenshotsNew,
      screenshotsOld: activeDeviceRender.historyData.screenshotsOld,
    }
  }
  return {
    show: false,
    screenshotsNew: {},
    screenshotsOld: {},
  }
})

function logClick(event) {
  highlightBox.value.scrollFunction()
}

let progressColor = computed(() => {
  if (activeDeviceRender.page.score >= 80) {
    return 'green'
  }
  if (activeDeviceRender.page.score >= 50) {
    return 'orange'
  }
  return 'red'

})
</script>

<template>
  <highlight-box ref="highlightBox" :highlight-details="highlightDetails"></highlight-box>

  <div v-if="scanStatus.site.status === 'error'">

    <div class="container">
      <div class="row justify-content-end">
        <div class="col-12 p-4 mt-2">
          <div class="alert alert-danger" role="alert">
            <span class="fa fa-warning"></span> <b>Unable to run scan.</b>
            <p>
              {{ scanStatus.site.message }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="activeDeviceRender.loaded" class="container" @click="logClick">
    <div class="row justify-content-end">
      <div class="col-12 col-md-6 col-xl-7"
           v-show="['in_progress', 'completed'].includes(activeDeviceRender.scanState)">
        <div class="d-flex justify-content-center align-items-center"
             :class="`preview-${activeDeviceRender.devicePreview.deviceId}`">
          <table>
            <tbody>
            <tr>
              <td class="d-none d-lg-table-cell" style="width:30px">
                <button
                    type="button"
                    class="btn btn-sm btn-alt-secondary mb-1 ms-3 ms-xl-0"
                    v-tooltip="`Scroll Up`"
                    @click="scroll('up')"
                >
                  <i class="fa fa-fw fa-up-long"/>
                </button>
                <br>
                <button
                    type="button"
                    class="btn btn-sm btn-alt-secondary mb-1  ms-3 ms-xl-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    v-tooltip="`Scroll Down`"
                    @click="scroll('down')"
                >
                  <i class="fa fa-fw fa-down-long"/>
                </button>
                <br>
                <a
                    type="button"
                    :href="activeDeviceRender.page.url"
                    target="_blank"
                    class="btn btn-sm btn-alt-secondary mt-1  ms-3 ms-xl-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    v-tooltip="`${activeDeviceRender.page.url}`"
                >
                  <i class="fa fa-link"/>
                </a>
              </td>

              <td class="text-center">
                <div style="position: relative;white-space: nowrap;min-width:220px;">
                  <device-preview ref="devicePreview" v-if="activeDeviceRender.loaded" :maxHeight="800"
                                  :maxWidth="maxWidth"
                                  :history="historyPreview"
                                  @loaded="onLoaded"
                                  :disableScreenshotMain="!!route.query.section"
                                  @on-manual-scroll="onManualScroll"
                                  @on-scroll-to-entry-completed="onScrollToEntryCompleted"
                                  v-bind="activeDeviceRender.devicePreview"></device-preview>
                  <br>

                </div>


              </td>
              <td>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
        <div class="d-flex justify-content-center align-items-center pb-2">

          <span class="dropdown">
                  <button
                      type="button"
                      class="btn btn-sm btn-alt-primary dropdown-toggle me-2"
                      id="dropdown-default-alt-primary"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    {{ activeDeviceRender.devicePreview.deviceName }}
                  </button>
                  <div
                      class="dropdown-menu fs-sm"
                      aria-labelledby="dropdown-default-alt-primary"
                  >
                    <a class="dropdown-item" href="#here" @click.prevent="replaceLink(device.deviceId)"
                       v-for="device in activeDeviceRender.deviceRenders">{{ device.name }}</a>
                  </div>
                </span>
        </div>
      </div>
      <div
          class="col-12 col-md-6 col-xl-5 ps-4 pe-4 justify-content-end load-transition max-height-overflow" style="border-left: 1px solid #80808021;">

        <template v-if="route.query.section === 'ai'">
          <ai-section :feedback-category="`design_feedback`" :devicePreview="devicePreview"
                      v-if="devicePreview"></ai-section>
        </template>
        <template v-if="route.query.section === 'insights'">
          <ai-section :feedback-category="`first_impressions`" :devicePreview="devicePreview"
                      v-if="devicePreview"></ai-section>
        </template>
        <template v-if="route.query.section === 'performance'">
          <performance-section :devicePreview="devicePreview" v-if="devicePreview"></performance-section>

        </template>
        <template v-if="route.query.section === 'history'">
          <history-section :devicePreview="devicePreview" v-if="devicePreview"/>
        </template>
        <template v-if="!route.query.section">
          <template v-if="['in_progress', 'completed'].includes(activeDeviceRender.scanState)">
            <p></p>

            <div class="alert alert-danger" role="alert"
                 v-if="!activeDeviceRender.page.httpStatusOkOrPending">
              <p class="mb-0"><b>Page Load Error</b><br> We could not load this page, the server responded with a status
                code of {{ activeDeviceRender.page.httpStatusCode }}. </p>
            </div>
            <div v-else>

              <div class="background-block me-2 p-3 pb-4">
                <RadialProgress style="float:right"
                                :diameter="68"
                                innerStrokeColor="gray"
                                :startColor="progressColor"
                                :stopColor="progressColor"
                                :strokeWidth="5"
                                :innerStrokeWidth="4"
                                :completed-steps="activeDeviceRender.page.score"
                                :total-steps="100">
                  <span v-if="activeDeviceRender.page.score">{{ Math.round(activeDeviceRender.page.score) }}</span>
                  <div class="spinner-border" v-if="activeDeviceRender.page.score === 0"
                       style="font-size:2px;color:#2F495E;position:absolute;left:2px;top:6px;width:56px;height:56px;">
                    <span class="sr-only">Loading..</span>
                  </div>
                </RadialProgress>
                <div class="mb-1">Page Score</div>
                <small class="overflow-text-ellipsis text-muted pe-3 mt-2">{{
                    activeDeviceRender.page.title || '-'
                  }}</small>
              </div>
              <div v-if="['in_progress'].includes(activeDeviceRender.scanState)" class="alert alert-success mt-2 me-2"
                   role="alert">
                <div class="spinner-border"
                     style="font-size:11px;color:#2F495E;width:16px;height:16px;">
                  <span class="sr-only">Loading..</span>
                </div>
                Waiting for scan results..
              </div>

              <div v-if="cloudFlareError" class="alert alert-danger mt-2">
                <div><span class="fa fa-warning"></span> <b>We had some issues checking this page</b></div>
                <div class="ms-2 mt-1">Cloudflare is blocking our requests. Please see instructions on how to fix this
                  issue.
                </div>
              </div>

              <div class="pe-2"
                   v-if="spellingMarks.length || layoutErrors.filter(e=>e.priority === 'high').length || brokenLinkMarks.length || brokenImageMarks.length || seoErrors.length || contentErrors.length">
                <span class="badge text-bg-warning mb-2 mt-4">High Priority</span>
                <issue-section id="spelling" title="Spelling Issue" :issues="spellingMarks"></issue-section>

                <issue-section id="layout" title="Layout Error"
                               :issues="layoutErrors.filter(e=>e.priority === 'high')"></issue-section>

                <issue-section id="broken_link" title="Broken Link" :issues="brokenLinkMarks"></issue-section>

                <issue-section id="broken_image" title="Broken Image" :issues="brokenImageMarks"></issue-section>

                <issue-section id="content_error" title="Content Issue" :issues="contentErrors"></issue-section>


                <issue-section id="seo_issue" title="SEO Issue" :issues="seoErrors"></issue-section>


              </div>
              <div class="pe-2"
                   v-if="consoleErrors.length || layoutErrors.filter(e=>e.priority === 'medium').length  || brokenResources.length || (performanceScore > 0 && performanceScore < 50) || seoWarnings.length || contentIssueMarks.length">

                <span class="badge  mb-2 mt-4" style="background-color:orange">Medium Priority</span>

                <issue-section id="layout" priority="medium" title="Layout Issue"
                               :issues="layoutErrors.filter(e=>e.priority === 'medium')"></issue-section>
                <issue-section id="content" priority="medium" title="Content Issue"
                               :issues="contentIssueMarks"></issue-section>


                <performance-issue v-if="(performanceScore > 0 && performanceScore < 50)"/>

                <issue-section id="seo_warning" title="SEO Issue" :issues="seoWarnings"></issue-section>

                <div class="accordion  issue-block position-relative">
                  <div class="accordion-item" v-if="consoleErrors.length > 0">
                    <h2 class="accordion-header">
                      <button class="accordion-button issue-header collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#issue-panel-console-errors"
                      >
                        {{ consoleErrors.length }} Error{{ consoleErrors.length > 1 ? 's' : '' }} printed to console
                      </button>
                    </h2>
                    <div id="issue-panel-console-errors" class="accordion-collapse collapse issue-body"
                         data-bs-parent="#accordion-issue-list">
                      <div class="accordion-body">

                        <ul class="list-group list-group-flush ">
                          <div
                              class="list-group-item list-group-item-action text-danger bg-danger-subtle rounded  issue-item"
                              v-for="error in consoleErrors">
                            <div class="issue-console-error"><span
                                class=" badge bg-danger">{{ error.occurrences }}</span> {{ error.text }}
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion issue-block position-relative">
                  <div class="accordion-item" v-if="brokenResources.length > 0">
                    <h2 class="accordion-header">
                      <button class="accordion-button issue-header collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#issue-panel-broken-resources"
                      >
                        {{ brokenResources.length }} Broken resource{{ brokenResources.length > 1 ? 's' : '' }}
                      </button>
                    </h2>
                    <div id="issue-panel-broken-resources" class="accordion-collapse collapse issue-body"
                         data-bs-parent="#accordion-issue-list">
                      <div class="accordion-body">

                        <ul class="list-group list-group-flush ">
                          <div
                              class="list-group-item list-group-item-action p-2 text-danger bg-warning-subtle rounded overflow-text-ellipsis issue-item"
                              v-for="error in brokenResources">
                            <small>

                              <http-status-code-badge :status-code="`${error.status}`"/>&nbsp;
                              <a target="_blank" title="External Link"
                                 :href="error.url" class="external-link-plain"> {{ error.url }}</a>

                            </small>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pe-2"
                   v-if="internalRedirectMarks.length || inconsistentCapitalizationMarks.length || (performanceScore > 50 && performanceScore < 80) || consoleWarnings.length > 0">

                <span class="badge  mb-2 mt-4" style="background-color:gray">Low Impact</span>

                <issue-section id="internal_redirect" title="Internal Redirect"
                               priority="medium" :issues="internalRedirectMarks"></issue-section>

                <issue-section id="inconsistent_capitalization" title="Capitalization Issue"
                               priority="low" :issues="inconsistentCapitalizationMarks"></issue-section>

                <performance-issue v-if="(performanceScore > 50 && performanceScore < 80)"/>

                <div class="accordion  issue-block position-relative">
                  <div class="accordion-item" v-if="consoleWarnings.length > 0">
                    <h2 class="accordion-header">
                      <button class="accordion-button issue-header collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#issue-panel-console-warnings"
                      >
                        {{ consoleWarnings.length }} Warning{{ consoleWarnings.length > 1 ? 's' : '' }} printed to
                        console
                      </button>
                    </h2>
                    <div id="issue-panel-console-warnings" class="accordion-collapse collapse issue-body"
                         data-bs-parent="#accordion-issue-list">
                      <div class="accordion-body">

                        <ul class="list-group list-group-flush ">
                          <div
                              class="list-group-item list-group-item-action rounded  issue-item"
                              style="background-color:#FEF6D5;color:#474747"
                              v-for="error in consoleWarnings">
                            <div class="issue-console-error"><span
                                class=" badge bg-warning">{{ error.occurrences }}</span> {{ error.text }}
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div
                  v-if="['completed'].includes(activeDeviceRender.scanState) && !(spellingMarks.length || layoutErrors.length || brokenLinkMarks.length || brokenImageMarks.length ||
                  internalRedirectMarks.length || inconsistentCapitalizationMarks.length || consoleErrors.length ||
                  brokenResources.length || seoErrors.length || seoWarnings.length || performanceScore < 80 || consoleWarnings.length || contentIssueMarks.length)">
                <p></p>
                <div class="alert alert-success" role="alert">
                  <span class="fa fa-thumbs-up"></span> No Issues Found
                </div>
              </div>
            </div>
          </template>
          <p></p>
        </template>
      </div>

    </div>

  </div>


</template>
<style>

.preview-chrome_1366 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.preview-chrome_1280 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.infoblock .block-content {
  padding: 4px;
  font-size: 94%;
}

.max-height-overflow {
  max-height: 800px;
  overflow-y: auto;
}

/* For Webkit browsers (e.g., Chrome, Safari) */
.max-height-overflow::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
  height: 5px;
}

.max-height-overflow::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scroll thumb */
  border-radius: 2px; /* roundness of the scroll thumb */
}

.max-height-overflow::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* color of the scroll thumb on hover */
}

.max-height-overflow::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* color of the track */
}

/* For Firefox */
.max-height-overflow {
  scrollbar-width: thin; /* makes the scrollbar thinner */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}


.issue-alert {
  background-color: red;
  z-index: 99;
  max-width: 450px;
  white-space: normal;
  color: white;
  padding: 4px;
  border-radius: 8px;
  font-size: 13px;
}

.issue-alert a {
  color: white;
  text-decoration: underline;
}

.issue-alert-code {
  margin-bottom: 0;
  overflow-wrap: break-word;
  margin-top: 4px;
  background-color: #464646;
  font-size: 11px;
  max-height: 54px;
}
</style>