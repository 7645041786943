<script setup>
import { onMounted, computed } from "vue";
import { useTemplateStore } from "@/stores/template";

import BaseNavigation from "@/components/BaseNavigation.vue";
// SimpleBar, for more info and examples you can check out https://github.com/Grsmto/simplebar/tree/master/packages/simplebar-vue
import SimpleBar from "simplebar";

// Grab menu navigation arrays
import menu from "@/data/menu";
import { useWebsiteStore } from "@/stores/website";
import GooglyEyes from "@/components/GooglyEyes.vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";

const route = useRoute();
const websiteStore = useWebsiteStore();
const userStore = useUserStore();
const {user} = storeToRefs(userStore);
const accountMenu = computed(() => {
  if (route.meta.sidebar === 'user') {
    return []
  }
  if (user.value.role === 'guest'){
    return []
  }
  return [
    {
      name: "Settings",
      heading: true,
    },
    {
      name: "Site Settings",
      to: {name: "settings"},
      icon: "si si-settings",
    },

  ]
})

const dmenu = computed(() => {
  if (route.meta.sidebar === 'user') {
    return menu.user()
  }
  return menu.main(websiteStore);
});

// Main store
const store = useTemplateStore();

// Init SimpleBar (custom scrolling)
onMounted(() => {
  try {
    new SimpleBar(document.getElementById("simplebar-sidebar"));
  } catch (e) {
    console.log(e)
  }

});
</script>

<template>
  <!-- Sidebar -->
  <!--
    Sidebar Mini Mode - Display Helper classes

    Adding 'smini-hide' class to an element will make it invisible (opacity: 0) when the sidebar is in mini mode
    Adding 'smini-show' class to an element will make it visible (opacity: 1) when the sidebar is in mini mode
    If you would like to disable the transition animation, make sure to also add the 'no-transition' class to your element

    Adding 'smini-hidden' to an element will hide it when the sidebar is in mini mode
    Adding 'smini-visible' to an element will show it (display: inline-block) only when the sidebar is in mini mode
    Adding 'smini-visible-block' to an element will show it (display: block) only when the sidebar is in mini mode
  -->
  <nav
      id="sidebar"
      aria-label="Main Navigation"
  >
    <slot>
      <!-- Side Header -->
      <div class="content-header">
        <slot name="header">
          <!-- Logo -->
          <RouterLink :to="{name: 'accountDashboard'}" class="fw-semibold pagewatch-logo">
            <googly-eyes></googly-eyes>
          </RouterLink>
          <!-- END Logo -->
        </slot>

        <!-- Extra -->
        <div>

          <!-- Close Sidebar, Visible only on mobile screens -->
          <button
              type="button"
              class="d-lg-none btn btn-sm btn-alt-secondary ms-1"
              @click="store.sidebar({ mode: 'close' })"
          >
            <i class="fa fa-fw fa-times"></i>
          </button>
          <!-- END Close Sidebar -->
        </div>
        <!-- END Extra -->
      </div>
      <!-- END Side Header -->

      <!-- Sidebar Scrolling -->

      <div id="simplebar-sidebar" class="js-sidebar-scroll">
        <slot name="content">
          <!-- Side Navigation -->
          <div class="content-side">
            <BaseNavigation :nodes="dmenu"/>

          </div>
          <!-- END Side Navigation -->


        </slot>

      </div>
      <div style="position: absolute;bottom: 0rem;height:8rem;padding:0;margin:0;width:100%">
        <div class="content-side" style="padding-bottom:0;padding-top:0;margin:0">
          <BaseNavigation :nodes="accountMenu"/>
        </div>
      </div>
      <!--    <div class="p-0">This div is aligned to the bottom!</div>-->


      <!-- END Sidebar Scrolling -->

    </slot>
  </nav>
  <!-- END Sidebar -->
</template>
<style>
.pagewatch-logo {
  color: #FFA328;
}
</style>