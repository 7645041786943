<script setup>
import { computed, ref } from "vue";
import { useUserStore } from "@/stores/user";
// import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import { useTemplateStore } from "@/stores/template";

import { storeToRefs } from "pinia";
import axios from "axios";
import { useWebsiteStore } from "@/stores/website";
import { useAuth } from "@/composables/auth";
import { useRoute } from "vue-router";
import ShareModal from "@/layouts/partials/ModalShareReport.vue";
import ModalShareReport from "@/layouts/partials/ModalShareReport.vue";

const auth = useAuth()
const route = useRoute();
// Main store and Router
const store = useTemplateStore();
const userStore = useUserStore();
const websiteStore = useWebsiteStore();
const {website, scanStatus} = storeToRefs(websiteStore);
const {user} = storeToRefs(userStore);
// const activeDeviceRenderStore = useActiveDeviceRenderStore()
const imageRoot = import.meta.env.VITE_IMAGE_ROOT

const filteredUserWebsites = computed(() => {
  if (user.value.websites) {
    let sites = [];
    sites.push(...user.value.websites.filter((website) => {
      return true;
      // return website.id !== user.value.activeWebsite.id;
    }));
    return sites;
  }
  return []

})

const shareData = ref({
  url: 'xx',
  loading: true,
  website: {}
})

async function setActiveSite(website) {
  let r = await axios.post('/websites/active', {id: website.id})
}

async function logout() {
  await auth.logout()
  return await router.push({name: 'auth-login'})
}

async function showModal(site) {
  shareData.value.loading = true;
  let myModal = new bootstrap.Modal(document.getElementById('modal-share-report'), {})
  myModal.show()
  let r = await axios.post(`websites/${website.value.shortuuid}/share/`)
  if (r.data.success) {
    shareData.value.url = document.location.href.replace('/r/', '/' + r.data.user + '/')
    shareData.value.meta = r.data.website.meta
  } else {
    shareData.value.url = 'error'
  }
  shareData.value.loading = false;


}
</script>

<template>
  <!-- Header -->
  <modal-share-report v-bind="shareData"></modal-share-report>

  <header id="page-header">
    <slot>
      <!-- Header Content -->
      <div class="content-header">
        <slot name="content">
          <!-- Left Section -->
          <div class="d-flex align-items-center">
            <slot name="content-left">
              <!-- Toggle Sidebar -->
              <button
                  type="button"
                  class="btn btn-sm btn-alt-secondary me-2 d-lg-none"
                  @click="store.sidebar({ mode: 'toggle' })"
              >
                <i class="fa fa-fw fa-bars"></i>
              </button>
              <!-- END Toggle Sidebar -->
              <!-- User Dropdown -->
              <div class="dropdown">
                <button
                    type="button"
                    class="btn dropdown-toggle btn-website-select"
                    id="dropdown-default-primary"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  <span v-if="!website" class="website-select-favicon"></span>
                  <img v-else-if="website && website.previewFavicon" class="website-select-favicon me-2"
                       :src="`${imageRoot}${website.previewFavicon}?tr=w-24`">
                  <img v-else class="website-select-favicon" :src="`${imageRoot}favicon-default.png?tr=w-16`"
                       style="width:16px;height:16px">
                  <span v-if="route.meta.sidebar !== 'user'">{{ $filters.truncate(website.url) }}</span>
                  <span v-else>Select Site</span>
                </button>
                <template v-if="scanStatus.site.in_progress">
                <span class="spinner-border text-primary ms-2 me-2"
                      style="font-size:12px;height:18px;width:18px">      </span>
                  <span class="text-muted small">Scan in progress.</span>
                </template>
                <div
                    class="dropdown-menu fs-sm"
                    aria-labelledby="dropdown-default-primary"
                >
                  <router-link v-for="website in filteredUserWebsites" class="dropdown-item"
                               :to="{name: 'dashboard', params: {websiteId: website.shortuuid, reportRoot: 'r'}}">
                    {{ website.url }}
                  </router-link>
                  <div class="dropdown-divider" v-if="filteredUserWebsites.length > 0"></div>
                  <RouterLink v-if="user.role !== 'guest'" :to="{ name: 'newSite' }" class="dropdown-item"
                  >Add Another Site
                  </RouterLink>
                  <RouterLink v-else :to="{ name: 'auth-signup' }" class="dropdown-item"
                  >Sign Up to Check Another Site
                  </RouterLink>
                </div>
              </div>

            </slot>
          </div>
          <!-- END Left Section -->

          <!-- Right Section -->
          <div class="d-flex align-items-center">
            <slot name="content-right">
              <a href="#" @click.prevent='showModal' title="Share" v-if="website && user.role !== 'guest'"
                 class="me-2 external-link-plain"><span class="fa fa-share-alt"></span></a>

              <RouterLink v-if="user.role !== 'guest'"
                  :to="{ name: 'newSite' }"
                  class="btn btn-sm btn-alt-info d-flex align-items-center"
              >

                <i
                    class="fa fa-plus"
                ></i>
                <span class="d-none d-sm-inline-block ms-2">New Site</span>

              </RouterLink>
              <RouterLink v-else
                  :to="{ name: 'auth-login' }"
                  class="d-flex align-items-center"
              >
                <span class="d-none d-sm-inline-block ms-2">Sign In</span>

              </RouterLink>

              <!-- User Dropdown -->
              <div class="dropdown d-inline-block ms-2" v-if="user.role !== 'guest'">
                <button
                    type="button"
                    class="btn btn-sm btn-alt-secondary d-flex align-items-center"
                    id="page-header-user-dropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  <i
                      class="fa fa-user"
                  ></i>
                  <span class="d-none d-sm-inline-block ms-2">My Account</span>
                  <i
                      class="fa fa-fw fa-angle-down d-none d-sm-inline-block opacity-50 ms-1 mt-1"
                  ></i>
                </button>

                <div
                    class="dropdown-menu fs-sm"
                >
                  <router-link :to="{name: 'accountDashboard'}" class="dropdown-item">My Account</router-link>
                  <router-link :to="{name: 'backend-dashboard'}" v-if="user.email === 'lpellis@gmail.com'"
                               class="dropdown-item">Admin
                  </router-link>
                  <div role="separator" class="dropdown-divider m-0"></div>

                  <a href="#" @click.prevent="logout" class="dropdown-item">Log Out</a>

                </div>
              </div>
              <!-- END User Dropdown -->

            </slot>
          </div>
          <!-- END Right Section -->
        </slot>
      </div>
      <!-- END Header Content -->


    </slot>
  </header>
  <!-- END Header -->
</template>
<style>
.btn-website-select.show {
  border: 1px solid rgba(128, 128, 128, 0.22);
}

.website-select-favicon {
  margin-top: -3px;
  margin-right: 4px;
  width: 24px;
  height: 24px;
}
</style>