<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { EventBus } from '@/events/eventBus';

const notificationCredits = ref(null);
const notificationLogin = ref(null);
onMounted(() => {
      notificationCredits.value = new window.bootstrap.Toast(
          document.getElementById("notification-credits")
      );
      notificationLogin.value = new window.bootstrap.Toast(
          document.getElementById("notification-login")
      );
      EventBus.on('showNotification', showNotification);
    }
)

onUnmounted(() => {
  EventBus.off('showNotification', showNotification);
});

function showNotification(notification) {
  if (notification.type === 'credits') notificationCredits.value.show();
  if (notification.type === 'login') notificationLogin.value.show();

}

defineExpose({
  showNotification,
});
</script>
<template>
  <div id="notification-credits" data-bs-delay="5000" class="toast align-items-center text-bg-danger border-0"
       role="alert"
       aria-live="assertive"
       aria-atomic="true">
    <div class="toast-header">
      <i class="fa fa-warning text-danger me-2"></i>
      <strong class="me-auto">Insufficient Scan Credits</strong>
      <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
      ></button>
    </div>
    <div class="d-flex">

      <div class="toast-body">
        Please top up your scan credits to continue.<br>
        <router-link @click="notificationCredits.hide()" :to="{name: 'purchaseCredits'}"
                     class="btn btn-sm btn-primary mt-2">Top up
        </router-link>
      </div>

    </div>
  </div>
  <div id="notification-login" data-bs-delay="5000" class="toast align-items-center text-bg-danger border-0"
       role="alert"
       aria-live="assertive"
       aria-atomic="true">
    <div class="toast-header">
      <i class="fa fa-warning text-danger me-2"></i>
      <strong class="me-auto">Login Required</strong>
      <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
      ></button>
    </div>
    <div class="d-flex">

      <div class="toast-body">
        Please log in to continue.<br>
        <router-link @click="notificationLogin.hide()" :to="{name: 'auth-login'}"
                     class="btn btn-sm btn-primary mt-2">Log In
        </router-link>
      </div>

    </div>
  </div>
</template>

<style>

@media only screen and (min-width: 768px) {
}

@media only screen and (max-width: 1199px) and  (min-width: 768px) {

}


</style>